import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    var textMediaElements = document.querySelectorAll('.cmp-text-media');
    textMediaElements.forEach(function (element) {
      var mediaElement = element.querySelector('.cmp-text-media__media');
      if (mediaElement.classList.contains('cmp-text-media__media__position_top')) {
        element.style.flexDirection = 'column';
      } else {
        element.style.flexDirection = 'row';
      }
    });
  }
};