import { mapState, mapActions, mapMutations } from 'vuex';
import { getCookiebyName } from '@/utils/cookies.js';
import { TruckIcon } from '../../commerce/blocks/svg-elements/truck-icon/index';
import AlertIcon from '../../commerce/blocks/svg-elements/alert-icon/AlertIcon.vue';
import { useDebounceFn } from '@vueuse/core';
const debouncedFn = useDebounceFn(() => window.innerWidth < 1025, 1000);
export default {
  data() {
    return {
      formattedAddress: '',
      mobileAndTabletCheck: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.dccUserInfo?.data?.email;
    },
    teleportID() {
      return this.mobileAndTabletCheck ? '#mega-menu-truck-modal' : '#truckModal';
    },
    ...mapState('user', ['isAuthenticated', 'dccUserInfo']),
    ...mapState('browser', ['country'])
  },
  components: {
    TruckIcon,
    AlertIcon
  },
  created() {
    window.addEventListener('resize', this.setIsMobileOrTablet);
  },
  mounted() {
    this.$refs.skeletonRef.remove();
    this.$store.dispatch('user/updateDccUserInfo');
    if (this.isAuthenticated && this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.formattedAddress && this.$store.state.user.dccUserInfo?.data?.canShowTruck) {
      this.$refs.infoIconRef.classList.remove('hide');
    } else {
      this.$refs.infoIconRef.classList.add('hide');
    }
    this.setIsMobileOrTablet();
  },
  unmounted() {
    window.removeEventListener('resize', this.setIsMobileOrTablet);
  },
  watch: {
    dccUserInfo: {
      deep: true,
      handler(newVal) {
        let refreshedAddress = false;
        if (this.isAuthenticated) {
          this.isLoggedIn = true;
        }
        if (this.isAuthenticated && newVal?.data?.canShowTruck) {
          if (!refreshedAddress) {
            this.$store.dispatch('address/refreshAddress', {
              userId: this.$store.state.user.dccUserInfo?.data?.uid
            });
            refreshedAddress = true;
          }
          this.getUserCountry();
          this.getUserTown();
          this.getModifiedAddress();
          if (this.formattedAddress) {
            this.$refs.infoIconRef.classList.remove('hide');
          }
        } else {
          this.$refs.infoIconRef.classList.add('hide');
        }
      }
    }
  },
  methods: {
    getUserCountry() {
      const userCountry = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.country?.name;
      this.userCountry = userCountry || '';
    },
    getUserTown() {
      const userTown = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.town;
      this.userTown = userTown || '';
    },
    getModifiedAddress() {
      const defaultDeliveryAddress = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress;
      if (defaultDeliveryAddress && defaultDeliveryAddress.sapCustomerID || defaultDeliveryAddress.shipToAddress.sapCustomerID) {
        const sapCustomerID = defaultDeliveryAddress.sapCustomerID || defaultDeliveryAddress.shipToAddress.sapCustomerID.replace(/STD_/, '');
        const company = defaultDeliveryAddress.shipToAddress?.companyName;
        const companyName = company ? company : '';
        const formattedAddress = defaultDeliveryAddress.shipToAddress?.formattedAddress;
        this.formattedAddress = `${sapCustomerID} ${companyName}<br><br>${formattedAddress}`;
        return;
      }
      this.formattedAddress = '';
    },
    toggleAddressInfo() {
      if (!this.mobileAndTabletCheck) {
        if (this.formattedAddress) {
          this.$refs.formattedAddressRef.classList.toggle('hide');
        }
      }
    },
    setIsMobileOrTablet() {
      debouncedFn().then(value => {
        this.mobileAndTabletCheck = value;
      });
    }
  }
};