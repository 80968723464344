import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 0,
  class: "registration-content"
};
const _hoisted_4 = {
  class: "registration-text"
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = ["innerHTML"];
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  class: "btn-group"
};
const _hoisted_13 = ["disabled"];
const _hoisted_14 = {
  key: 1,
  class: "registration-content"
};
const _hoisted_15 = ["innerHTML"];
const _hoisted_16 = {
  class: "verification-form"
};
const _hoisted_17 = {
  class: "btn",
  type: "submit"
};
const _hoisted_18 = {
  key: 0
};
const _hoisted_19 = ["innerHTML"];
const _hoisted_20 = {
  key: 2,
  class: "registration-content"
};
const _hoisted_21 = ["innerHTML"];
const _hoisted_22 = {
  class: "btn-group"
};
import { ref, computed } from 'vue';
import DcModal from '../modal/DcModal.vue';
import DcTextInput from '../forms/DcTextInput.vue';
import LoadingDots from '../forms/DcLoadingDots.vue';
import { useStore } from 'vuex';

// Store

export default {
  __name: 'ConfirmRegistration',
  emits: ['postponeReauth'],
  setup(__props, {
    emit: __emit
  }) {
    const store = useStore();

    // Data
    const step = ref('choose');
    const confirmed = ref(false);
    const confirmationCode = ref('');
    const emit = __emit;
    const reauthModal = ref(null);
    const buttonConfirmClicked = ref(false);

    // Computed
    const reauthorizationRequired = computed(() => {
      return store.state.user.reauthorizationRequired;
    });
    const displayPostPone = computed(() => {
      let today = new Date();
      let tmpDueDate = new Date(parseInt(dueDate.value));
      let Difference_In_Time = tmpDueDate.getTime() - today.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days >= 2) {
        return true;
      } else {
        return false;
      }
    });
    const isVerificationError = computed(() => {
      return store.state.user.isVerificationError;
    });
    const isInternal = computed(() => {
      if (store.state.user.isSSOLogin) {
        return store.state.user?.dccUserInfo?.data?.isInternal == 'true' ? true : false;
      } else {
        return store.state.user?.dccUserInfo?.data?.isInternalUser;
      }
    });
    const reauthReason = computed(() => store.state.user?.dccUserInfo?.data?.reauthorizationReason);
    const loading = computed(() => {
      return store.state.user.loading;
    });
    const dueDate = computed(() => {
      if (store.state.user.isSSOLogin) {
        return store.state?.user?.dccUserInfo?.data?.reauthorizationDueDate;
      } else {
        return store.state?.user?.dccUserInfo?.data?.reauthorizationdueDate;
      }
    });
    const reauthorizationDueDate = computed(() => {
      if (dueDate.value) {
        let date = new Date(parseInt(dueDate.value));
        let month = parseInt(date.getUTCMonth());
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedUTCDate = date.getUTCDate() + '-' + months[month] + '-' + date.getUTCFullYear();
        return formattedUTCDate;
      }
      return null;
    });
    const labels = computed(() => {
      const externalLabels = {
        inactive: window.Granite.I18n.get('reauth.external.inactive'),
        annual: window.Granite.I18n.get('reauth.external.annual'),
        general2: window.Granite.I18n.get('reauth.external.general.line2'),
        general3: window.Granite.I18n.get('reauth.external.general.line3').replace('{{ReauthorizationDueDate}}', reauthorizationDueDate.value)
      };
      const internalLabels = {
        inactive: window.Granite.I18n.get('reauth.internal.inactive.nlir'),
        annual: window.Granite.I18n.get('reauth.internal.annual'),
        dateline: window.Granite.I18n.get('reauth.internal.dateline').replace('{{ReauthorizationDueDate}}', reauthorizationDueDate.value)
      };
      const globalLabels = {
        logout: window.Granite.I18n.get('reauth.remove.acct.logout'),
        confirm: window.Granite.I18n.get('reauth.confirm.acct'),
        postpone: window.Granite.I18n.get('reauth.postpone'),
        verificationMsg: window.Granite.I18n.get('registration.verificationMsg').replace("<span class='dcc_simple_registration_signup_registration_email'>", "<span class='dcc_simple_registration_signup_registration_email'>" + store.state?.user?.dccUserInfo?.data?.email),
        confirmation: window.Granite.I18n.get('confirmation.Title'),
        verificationTitle: window.Granite.I18n.get('reauth.verificationTitle'),
        verificationError: window.Granite.I18n.get('email.validate.token.invalid.message'),
        verificationCode: window.Granite.I18n.get('verification.code.Title'),
        resendCode: window.Granite.I18n.get('reauth.resendCode'),
        removeMsg: window.Granite.I18n.get('reauth.remove.info.msg'),
        verify: window.Granite.I18n.get('reauth.verify'),
        remove: window.Granite.I18n.get('reauth.remove.acct'),
        cancel: window.Granite.I18n.get('button.cancel.Title')
      };
      return isInternal.value === true ? {
        ...internalLabels,
        ...globalLabels
      } : {
        ...externalLabels,
        ...globalLabels
      };
    });
    const postponed = computed(() => store.state.user.reauthPostponed === 'true' ? true : false);

    // Methods
    const switchStep = value => {
      reauthModal.value?.querySelector('input:not([type="hidden"]), button, a, textarea')?.focus();
      step.value = value;
    };
    const postpone = () => {
      confirmed.value = false;
      store.dispatch('user/postponeAccount', {
        cmd: 'postpone'
      });
      if (!store.state.user.isSSOLogin) {
        emit('postponeReauth');
        if (store.state.user.reloadRequired === true) {
          store.dispatch('user/reloadPage');
        }
      }
    };
    const verifyAccount = async () => {
      buttonConfirmClicked.value = true;
      if (isInternal.value || isInternal.value === 'true') {
        await store.dispatch('user/verifyAccount', {
          cmd: 'confirmAcct'
        });
        buttonConfirmClicked.value = false;
      } else {
        store.commit('user/isVerificationError', false);
        await store.dispatch('user/verifyAccount', {
          cmd: 'verify',
          confirmationCode: confirmationCode.value.trim().toUpperCase()
        });
        buttonConfirmClicked.value = false;
      }
    };
    const resendCode = async () => {
      store.commit('user/isVerificationError', false);
      await store.dispatch('user/verifyAccount', {
        cmd: 'resend'
      });
    };
    const goToExternalVerification = async () => {
      buttonConfirmClicked.value = true;
      await resendCode();
      switchStep('confirm');
    };
    const removeAccount = async () => {
      await store.dispatch('user/deleteAccount', {
        cmd: 'delAcct'
      });
      store.dispatch('user/reloadPage');
    };
    return (_ctx, _cache) => {
      const _component_DcForm = _resolveComponent("DcForm");
      return reauthorizationRequired.value && reauthorizationDueDate.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "reauthModal",
        ref: reauthModal
      }, [!postponed.value ? (_openBlock(), _createBlock(DcModal, _mergeProps({
        key: 0,
        cssClasses: step.value,
        onModalOpen: _cache[8] || (_cache[8] = $event => switchStep('choose')),
        onModalClose: _cache[9] || (_cache[9] = $event => postpone())
      }, _ctx.$attrs), {
        header: _withCtx(() => [step.value === 'confirm' && !isInternal.value ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(labels.value.verificationTitle), 1)) : (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(labels.value.confirmation), 1))]),
        body: _withCtx(() => [step.value === 'choose' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [isInternal.value ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [reauthReason.value === 'Inactive' || reauthReason.value === 'NLIR' ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          innerHTML: labels.value.inactive,
          id: "InternalInactiveNlir"
        }, null, 8, _hoisted_5)) : _createCommentVNode("", true), reauthReason.value === 'Annual' ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          innerHTML: labels.value.annual,
          id: "InternalAnnual"
        }, null, 8, _hoisted_6)) : _createCommentVNode("", true), _createElementVNode("p", {
          innerHTML: labels.value.dateline,
          id: "InternalDateline"
        }, null, 8, _hoisted_7)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [reauthReason.value === 'Inactive' ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          innerHTML: labels.value.inactive,
          id: "ExternalInactive"
        }, null, 8, _hoisted_8)) : _createCommentVNode("", true), reauthReason.value === 'Annual' ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          innerHTML: labels.value.annual,
          id: "ExternalAnnual"
        }, null, 8, _hoisted_9)) : _createCommentVNode("", true), _createElementVNode("p", {
          innerHTML: labels.value.general2,
          id: "ExternalGeneralLine2"
        }, null, 8, _hoisted_10), _createElementVNode("p", {
          innerHTML: labels.value.general3,
          id: "ExternalGeneralLine3"
        }, null, 8, _hoisted_11)], 64))]), _createElementVNode("div", _hoisted_12, [_createElementVNode("button", {
          type: "button",
          class: "btn",
          disabled: buttonConfirmClicked.value,
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => isInternal.value ? verifyAccount() : goToExternalVerification(), ["prevent"]))
        }, _toDisplayString(labels.value.confirm), 9, _hoisted_13), _createElementVNode("button", {
          type: "button",
          class: "btn--ghost-red",
          onClick: _cache[1] || (_cache[1] = $event => switchStep('remove'))
        }, _toDisplayString(labels.value.logout), 1), displayPostPone.value ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = $event => postpone()),
          type: "button",
          class: "btn--underline-blue"
        }, _toDisplayString(labels.value.postpone), 1)) : _createCommentVNode("", true)]), loading.value ? (_openBlock(), _createBlock(LoadingDots, {
          key: 0
        })) : _createCommentVNode("", true)])) : step.value === 'confirm' ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("p", {
          innerHTML: labels.value.verificationMsg
        }, null, 8, _hoisted_15), _createVNode(_component_DcForm, {
          id: "verify-account",
          onSubmit: _cache[5] || (_cache[5] = $event => verifyAccount())
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createVNode(DcTextInput, {
            type: "text",
            name: "verificationCode",
            label: labels.value.verificationCode,
            nolabel: "",
            rules: "required",
            modelValue: confirmationCode.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => confirmationCode.value = $event),
            placeholderInput: "Verification Code"
          }, null, 8, ["label", "modelValue"]), _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = _withModifiers($event => resendCode(), ["prevent"])),
            class: "btn--underline-blue",
            type: "button"
          }, _toDisplayString(labels.value.resendCode), 1)]), _createElementVNode("div", null, [_createElementVNode("button", _hoisted_17, _toDisplayString(labels.value.verify), 1)]), isVerificationError.value ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("p", {
            role: "alert",
            class: "dc-form__error errormsg",
            innerHTML: labels.value.verificationError
          }, null, 8, _hoisted_19)])) : _createCommentVNode("", true), loading.value ? (_openBlock(), _createBlock(LoadingDots, {
            key: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        })])) : step.value === 'remove' ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("p", {
          innerHTML: labels.value.removeMsg
        }, null, 8, _hoisted_21), _createElementVNode("div", _hoisted_22, [_createElementVNode("button", {
          type: "button",
          class: "btn",
          onClick: _cache[6] || (_cache[6] = $event => removeAccount())
        }, _toDisplayString(labels.value.remove), 1), _createElementVNode("button", {
          type: "button",
          class: "btn--ghost-red",
          onClick: _cache[7] || (_cache[7] = $event => switchStep('choose'))
        }, _toDisplayString(labels.value.cancel), 1)]), loading.value ? (_openBlock(), _createBlock(LoadingDots, {
          key: 0
        })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
        _: 1
      }, 16, ["cssClasses"])) : _createCommentVNode("", true)], 512)) : _createCommentVNode("", true);
    };
  }
};