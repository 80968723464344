import CoreComponent from '../../../core/CoreComponent.vue';
import DeliveryTruckModal from '../modal/index.vue';
import i18nHelper from '../../../commerce/myAccount/helper/i18n-helper.js';
import { getCookiebyName } from '@/utils/cookies.js';
import { UserHelper } from '../../../../../helpers/user-helper';
import { mapState } from 'vuex';
export default {
  extends: CoreComponent,
  components: {
    DeliveryTruckModal
  },
  data() {
    return {
      content: '',
      hideTruck: UserHelper.hideTruckBanner()
    };
  },
  watch: {
    '$store.state.user.isAuthenticated': function (loggedIn) {
      this.hideTruck = UserHelper.hideTruckBanner();
      if (loggedIn && !UserHelper.hideTruckBanner()) {
        this.removeEventListeners();
      } else {
        this.addEventListeners();
      }
    },
    country(newVal) {
      const selected = document.querySelector(`.cmp-delivery-truck-logged-out__modal-bottom-wrapper li[data-country-code="${newVal}"]`);
      if (selected && !this.$store.state.user.isAuthenticated) {
        document.querySelectorAll('.cmp--header_truck-modal .userDeliveryAddress').forEach(d => d.innerHTML = selected.dataset.countryName);
      }
    }
  },
  mounted() {
    this.content = this.cmpEl.outerHTML;
    this.$store.dispatch('user/isLoggedIn').then(() => {
      if (!this.$store.state.user.isAuthenticated || UserHelper.hideTruckBanner()) {
        this.addEventListeners();
      }
    });
    if (this.country) {
      const selected = document.querySelector(`.cmp-delivery-truck-logged-out__modal-bottom-wrapper li[data-country-code="${this.country}"]`);
      if (selected && !this.$store.state.user.isAuthenticated) {
        document.querySelectorAll('.cmp--header_truck-modal .userDeliveryAddress').forEach(d => d.innerHTML = selected.dataset.countryName);
      }
    }
  },
  computed: {
    ...mapState('browser', ['country'])
  },
  methods: {
    openTruckModal(focusOnClose = false) {
      this.$refs.deliveryTruckLoggedOutModal.openModal(focusOnClose);
      this.$nextTick(() => {
        const countryListWrapper = document.querySelectorAll('.cmp--header .cmp-delivery-truck-logged-out__modal-bottom-wrapper');
        const applyButtons = document.querySelectorAll('.cmp--header .cmp-delivery-truck-logged-out__button-apply');
        applyButtons.forEach(button => {
          button.addEventListener('click', e => {
            this.storeUserCountry();
            this.closeTruckModal();
          });
        });
        const loginButtons = document.querySelectorAll('.cmp-delivery-truck-logged-out__button-login');
        if (this.$store.state.user.isAuthenticated) {
          loginButtons.forEach(button => {
            button.style.visibility = 'hidden';
          });
        } else {
          loginButtons.forEach(button => {
            button.style.visibility = 'visible';
            button.addEventListener('click', () => {
              this.closeTruckModal();
              this.openLogInModal();
            });
          });
        }
        countryListWrapper.forEach(item => {
          const button = item.querySelector('button');
          const buttonText = item.querySelector('button span:first-child');
          const listItemsUl = item.querySelector('ul[role="list"]');
          const listItems = item.querySelectorAll('li[role="listitem"]');
          button.addEventListener('click', e => {
            e.stopPropagation();
            item.classList.toggle('cmp-delivery-truck-logged-out__modal-bottom-wrapper-open');
            listItemsUl.focus();
          });
          listItemsUl.addEventListener('keydown', e => {
            const pressedKey = e.key.toUpperCase();
            if (!/^[A-Z0-9]$/.test(pressedKey)) {
              return;
            }
            for (const item of listItems) {
              if (item.textContent.replace(/\n/g, '').trim().toUpperCase().startsWith(pressedKey)) {
                item.focus();
                break;
              }
            }
          });
          listItems.forEach(li => {
            li.addEventListener('keyup', e => {
              if (e.keyCode === 13) {
                listItems.forEach(otherLi => {
                  otherLi.classList.remove('item-selected');
                });
                li.classList.add('item-selected');
                const selectedCountry = li.getAttribute('data-country-name');
                buttonText.textContent = selectedCountry;
                item.classList.remove('cmp-delivery-truck-logged-out__modal-bottom-wrapper-open');
              }
            });
            li.addEventListener('click', () => {
              listItems.forEach(otherLi => {
                otherLi.classList.remove('item-selected');
              });
              li.classList.add('item-selected');
              const selectedCountry = li.getAttribute('data-country-name');
              buttonText.textContent = selectedCountry;
              item.classList.remove('cmp-delivery-truck-logged-out__modal-bottom-wrapper-open');
            });
          });
        });
      });
    },
    closeTruckModal() {
      this.$refs.deliveryTruckLoggedOutModal.closeModal();
    },
    getLabel: function (key) {
      return !!i18nHelper.getI18nMessage(key) ? i18nHelper.getI18nMessage(key) : key;
    },
    openLogInModal() {
      if (document.querySelector('.dc-modal')) {
        setTimeout(() => {
          document.querySelector('.dc-modal').classList.add('active');
        }, 300);
      }
    },
    storeUserCountry() {
      const selected = document.querySelector('.cmp-delivery-truck-logged-out__modal-bottom-wrapper li[role="listitem"].item-selected');
      if (selected) {
        const countryCode = selected.dataset.countryCode;
        this.$store.dispatch('browser/setBrowserCookie', {
          key: 'country',
          value: countryCode
        }).then(response => {
          if (localStorage.getItem('dowComCoveoToken')) localStorage.removeItem('dowComCoveoToken');
          this.$store.state.browser.country = countryCode;
          var isBasic = false;
          if (this.$store.state.user.dccUserInfo?.data?.email) {
            var roles = this.$store.state.user.dccUserInfo.data.roles;
            for (var i = 0; i < roles.length; i++) {
              if (roles[i].uid == 'basicuser') {
                isBasic = true;
                break;
              }
            }
            if (isBasic) {
              location.reload();
            }
          } else if (!this.$store.state.user.dccUserInfo?.data?.email || isBasic) {
            location.reload();
          }
        });
        const countryName = selected.dataset.countryName;
        if (countryName) {
          const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal .userDeliveryAddress');
          deliveryTruckButtons.forEach(d => d.innerHTML = countryName);
        }
      }
    },
    addEventListeners() {
      const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal');
      if (deliveryTruckButtons) {
        deliveryTruckButtons.forEach(button => {
          button.addEventListener('click', this.openModalWithFalse);
          button.addEventListener('keydown', this.handleKeydown);
        });
      }
    },
    removeEventListeners() {
      const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal');
      if (deliveryTruckButtons) {
        deliveryTruckButtons.forEach(button => {
          button.removeEventListener('click', this.openModalWithFalse);
          button.removeEventListener('keydown', this.handleKeydown);
        });
      }
    },
    openModalWithFalse() {
      this.openTruckModal(false);
    },
    handleKeydown(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.openTruckModal(true);
      }
    }
  }
};