import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Keyboard, EffectFade, Grid } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import { detectFileDownloadLink } from '../../core/ACDLHandler';
import { carouselClicksTracking } from '../../core/ACDLHandler';
export default {
  extends: CoreComponent,
  mounted() {
    const smartCrop = this.cmpEl.dataset.smartcrop;
    const cmpId = this.cmpId;
    const cardimages = this.cmpEl.querySelectorAll('.card-image');
    const bulletButtonAriaLabel = window.Granite.I18n.get('cardcontainer.carousel.pagination');
    cardimages.forEach(image => {
      let pureUrl = image.src.split('?')[0];
      let originalImageSrc = '';
      let cropedImageSrc = '';
      cropedImageSrc = `${pureUrl}:${smartCrop}?fmt=webp`;
      originalImageSrc = `${image.src}`;
      image.src = cropedImageSrc;
      image.addEventListener('error', function handleError() {
        image.src = `${originalImageSrc}`;
      }, {
        once: true
      });
    });
    const images = this.cmpEl.querySelectorAll('img[src*="scene7"][src*="dynamicmedia.dow.com"]');
    images.forEach(image => {
      s7responsiveImage(image);
    });
    const contentCarousel = this.cmpEl.querySelector('.cmp-cardcontainer-carousel__content');
    if (contentCarousel) {
      const swiper = new Swiper(contentCarousel, {
        slidesPerView: 1,
        spaceBetween: 16,
        slideToClickedSlide: true,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-cardcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        on: {
          slideChange: function () {
            carouselClicksTracking(cmpId, "Content Carousel", this.snapIndex);
          }
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      });
    }
    const resourcesCarousel = this.cmpEl.querySelector('.cmp-cardcontainer-carousel__resources');
    if (resourcesCarousel) {
      new Swiper(resourcesCarousel, {
        slidesPerView: 1,
        spaceBetween: 16,
        slideToClickedSlide: true,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-cardcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        on: {
          slideChange: function () {
            carouselClicksTracking(cmpId, "Resources Carousel", this.snapIndex);
          }
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      });
    }
    const announcementsCarousel = this.cmpEl.querySelector('.cmp-cardcontainer-carousel__announcements');
    if (announcementsCarousel) {
      new Swiper(announcementsCarousel, {
        slidesPerView: 1,
        spaceBetween: 16,
        slideToClickedSlide: true,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-cardcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        on: {
          slideChange: function () {
            carouselClicksTracking(cmpId, "Announcements Carousel", this.snapIndex);
          }
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      });
    }
    const panelCarousel = this.cmpEl.querySelector('.cmp-cardcontainer__card-image__carousel');
    if (panelCarousel) {
      new Swiper(panelCarousel, {
        slidesPerView: 1,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-cardcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        on: {
          slideChange: function () {
            carouselClicksTracking(cmpId, "Panel Carousel", this.snapIndex);
          }
        }
      });
    }
    const sustainabilityCarousel = this.cmpEl.querySelector('.carousel__utilityhomepage .cmp-cardcontainer-carousel__utilityhomepage');
    if (sustainabilityCarousel) {
      var sustainabilityView = new Swiper(sustainabilityCarousel, {
        slidesPerView: 1,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-cardcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        breakpoints: {
          1140: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          1024: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1
          }
        },
        on: {
          slideChange: function () {
            const visibleSlide = this.slides[this.activeIndex];
            const buttonElement = visibleSlide.querySelector('.swiper-slide-visible .cmp-cardcontainer__card-buttons');
            //set the active bullet color by check if active/visible slide has sustainability class
            if (buttonElement.classList.contains('has-sustainability_button')) {
              this.el.querySelector('.carousel__utilityhomepage .cmp-cardcontainer-carousel__utilityhomepage .swiper-pagination-bullet-active').style.backgroundColor = '#00857E';
            } else {
              this.el.querySelector('.carousel__utilityhomepage .cmp-cardcontainer-carousel__utilityhomepage .swiper-pagination-bullet-active').style.backgroundColor = '#E80033';
            }

            //set previous(original) slide to white
            const previsouInvisibleSlide = this.slides[this.previousIndex];
            const paginationElement = this.el.querySelector('.cmp-cardcontainer--carousel-pagination');
            const bulletElements = paginationElement.querySelectorAll('.swiper-pagination-bullet');
            bulletElements.forEach(function (bulletElement) {
              if (!bulletElement.classList.contains('swiper-pagination-bullet-active')) {
                bulletElement.style.backgroundColor = '#fff';
              }
            });
            carouselClicksTracking(cmpId, "Sustainability Carousel", this.snapIndex);
          }
        }
      });

      //when initialize set the active bullet color by check if active/visible slide has sustainability class
      const element = this.cmpEl.querySelector('.carousel__utilityhomepage .cmp-cardcontainer-carousel__utilityhomepage .swiper-pagination-bullet-active').parentElement.parentElement.querySelector('.swiper-slide-active .cmp-cardcontainer__card-buttons');
      if (element && element.classList.contains('has-sustainability_button')) {
        this.cmpEl.querySelector('.carousel__utilityhomepage .cmp-cardcontainer-carousel__utilityhomepage .swiper-pagination-bullet-active').style.backgroundColor = '#00857E';
      }
    }
    const crossSellCarousel = this.cmpEl.querySelector('.cmp-cardcontainer-carousel__crosssell');
    if (crossSellCarousel) {
      var swiperCrossSell = new Swiper(crossSellCarousel, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 30,
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        modules: [Keyboard, Pagination, Grid],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: '.cmp-cardcontainer--carousel-pagination',
          clickable: true,
          enabled: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        on: {
          slideChange: function () {
            carouselClicksTracking(cmpId, "CrossSell Carousel", this.snapIndex);
          }
        },
        grid: {
          rows: 4,
          fill: 'row'
        },
        watchOverflow: true,
        on: {
          init() {
            if (this.slides.length > 3) {
              this.el.classList.add('cmp-cardcontainer-carousel__crosssell-grid');
            }
          }
        },
        breakpoints: {
          1025: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesPerGroup: 2,
            grid: {
              rows: 2
            }
          }
        }
      });
    }
    // Make equal title height for pdp style
    window.addEventListener('load', this.adjustPdpTitleHeights());
    window.addEventListener('resize', this.adjustPdpTitleHeights());

    //File Download Tracking
    detectFileDownloadLink(this.cmpEl);
    document.addEventListener('focusin', this.onFocusedElementChange);
  },
  beforeUnmount() {
    document.removeEventListener('focusin', this.onFocusedElementChange);
  },
  methods: {
    adjustPdpTitleHeights() {
      let pdpTitles = document.querySelectorAll('.cmp-cardcontainer.grid__product-highlight-pdp .cmp-cardcontainer__card-title');
      pdpTitles.forEach(title => {
        title.style.minHeight = 'auto';
      });
      let maxPdpTitleHeight = 0;
      pdpTitles.forEach(title => {
        let currentHeight = title.clientHeight;
        if (currentHeight > maxPdpTitleHeight) {
          maxPdpTitleHeight = currentHeight;
        }
      });
      pdpTitles.forEach(title => {
        title.style.minHeight = maxPdpTitleHeight + 'px';
      });
    },
    onFocusedElementChange() {
      const swiperCards = this.cmpEl.querySelectorAll('.swiper-slide');
      swiperCards.forEach(card => {
        const focusableElements = card.querySelectorAll('a, button');
        if (!card.classList.contains('swiper-slide-visible')) {
          focusableElements.forEach(el => {
            el.tabIndex = -1;
          });
        } else {
          focusableElements.forEach(el => {
            el.tabIndex = 0;
          });
        }
      });
    }
  }
};