import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchBox = _resolveComponent("SearchBox");
  const _component_Cart = _resolveComponent("Cart");
  return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(), _createBlock(_Teleport, {
    to: "#global-in-page-search-box"
  }, [_createVNode(_component_SearchBox)])), $options.hasCartItems ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: ".cart-wrapper"
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.isButtonHovered ? 'visible' : 'hidden')
  }, [_createVNode(_component_Cart)], 2)])) : _createCommentVNode("", true)], 64);
}