import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const labels = this.cmpEl.querySelectorAll('.cmp-clp-labels__labels-item');
    this.cmpEl.querySelector('select').addEventListener('change', e => {
      labels.forEach(label => {
        label.style.display = label.dataset.languageCode === e.target.value ? 'block' : 'none';
      });
    });
  }
};