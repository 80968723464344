import { UserHelper } from '../helpers/user-helper'
import i18nHelper from '@platform/dow-platform/components/commerce/myAccount/helper/i18n-helper'

var FILE_REGEX =
    /(document-viewer\.html|\.(doc|docx|eps|jpg|png|svg|xls|ppt|pptx|pdf|xlsx|tab|csv|zip|txt|vsd|vxd|xml|js|css|rar|exe|wma|mov|avi|wmv|mp3|wav|m4v|dwg))$/i
var languageCode = getLanguageCode()
const VIDEO_VIEW_EVENT_DLM = 'Video Viewed'
const CALC_INTERACTION_EVENT_DLM = 'Calculator Interacted'
const FILE_DOWNLOAD = 'file:download'
const FILE_DOWNLOAD_FAILED = 'download:fail'
const VIDEO_INTERACTION = 'video:interaction'
const VIDEO_MILESTONE = 'video:milestone'

export default {
    namespaced: true,
    actions: {
        video: videoView,
        calculator: calculatorInteraction,
        download: fileDownloaded,
        downloadFailed: { root: true, handler: fileDownloadFailed },
        videoInteraction,
        videoMilestone,
        pageViewed: { root: true, handler: pageViewed },
        addToCartTracking: async function addToCartTracking(
            context,
            { step, products, cart }
        ) {
            const userObj = UserHelper.getUserData()
            var shipToIdData = userObj?.selectedShipToId ?? 'Undefined'
            var soldToIdData = userObj?.selectedSoldToId ?? 'Undefined'
            var companyName =
                userObj?.defaultDeliveryAddress?.shipToAddress?.companyName ??
                'Undefined'

            await fetchProductAttributes(context, products)

            window.adobeDataLayer.push({
                event: 'cart:add',
                productListItems: products,
                user: {
                    shipTo:
                        (shipToIdData || 'Undefined') +
                        '|' +
                        (companyName || 'Undefined'),
                    soldTo:
                        (soldToIdData || 'Undefined') +
                        '|' +
                        (companyName || 'Undefined')
                },
                eventInfo: {
                    step: step
                },
                cartId: sessionStorage.getItem('cartGuid'),
                cart: cart
            })
        },
        [FILE_DOWNLOAD]: { root: true, handler() {} },
        [FILE_DOWNLOAD_FAILED]: { root: true, handler() {} },
        [VIDEO_INTERACTION]: { handler: () => {}, root: true },
        [VIDEO_MILESTONE]: { handler: () => {}, root: true }
    }
}

function videoInteraction({ dispatch }, { cmpId, status }) {
    dispatch(
        'trackCmpInteraction',
        {
            event: VIDEO_INTERACTION,
            cmpId,
            data: {
                videoStatus: { status }
            }
        },
        { root: true }
    )
}

function videoMilestone({ dispatch }, { cmpId, milestone }) {
    dispatch(
        'trackCmpInteraction',
        {
            event: VIDEO_MILESTONE,
            cmpId,
            data: {
                videoMilestone: { milestone }
            }
        },
        { root: true }
    )
}

function videoView(context, video) {
    context.dispatch(
        'track',
        {
            event: VIDEO_VIEW_EVENT_DLM,
            video: {
                assetID: video.id,
                videoID: video.name,
                viewedPercentage: video.percent || 0,
                fileURL: video.url
            }
        },
        { root: true }
    )
}

function calculatorInteraction(context, calculator) {
    context.dispatch(
        'track',
        {
            event: CALC_INTERACTION_EVENT_DLM,
            calculator: {
                assetID: calculator.id,
                fileName: calculator.name,
                calculatorType: calculator.type,
                fileURL: calculator.url
            }
        },
        { root: true }
    )
}

async function fileDownloaded(context, file) {
    await fetchProductAttributes(context, file.products)

    window.adobeDataLayer.push({
        event: FILE_DOWNLOAD,
        productListItems: file.products,
        file: {
            [file.isOrder
                ? 'orderNumber'
                : file.isDelivery
                ? 'deliveryNumber'
                : 'assetID']: file.id,
            fileName: file.name,
            fileType: file.type,
            fileURL: file.url,
            siteLanguage: file.siteLanguage || languageCode || 'undefined',
            fileLanguage:
                file.docLanguage || file.language || languageCode || 'undefined'
        }
    })
}

async function fetchProductAttributes(context, products) {
    const gmidCodes = products.map((item) => item.gmid).join(',')

    let productCodes = []

    if (gmidCodes.length === 0) {
        productCodes = products.map((item) => item.productID).join(',')
    }
    try {
        let payload = {}
        if (productCodes.length > 0) {
            payload = {
                productCodes: productCodes,
                fields: 'businesses,businessgroups,categories'
            }
        } else if (gmidCodes.length > 0) {
            payload = {
                materialCodes: gmidCodes,
                fields: 'businesses,businessgroups,categories'
            }
        }
        await context.dispatch('product/fetchProductAttributes', payload, {
            root: true
        })

        for (const product of products) {
            product.SKU = product.productID || product.gmid
            product.productCategories =
                context.rootGetters['product/getCategories'][product.SKU]
            product.business =
                context.rootGetters['product/getBusinesses'][product.SKU]
            product.businessGroup =
                context.rootGetters['product/getBusinessGroups'][product.SKU]
        }
    } catch (error) {
        console.error('Error fetching product attributes', error)
    }
}

async function fileDownloadFailed(context) {
    let product = document.querySelector(
        '.document-viewer-title-wrapper > .title > span'
    )?.innerText
    let url_string = window.location.href
    let url = new URL(url_string)
    let params = url.searchParams
    let materialNumber = params.get('materialNumber')
    let prodID =
        params.get('product') ||
        params.get('materialNumber') ||
        params.get('code') //get product ID from url params
    let docType = params.get('docType')
    let documentType = params.has('languageJson')
        ? JSON.parse(params.get('languageJson'))[0].documentType
        : null
    if (documentType) {
        const isExportDoc = documentType == 'ZFINDOCPAC'
        const docLabelKey = isExportDoc
            ? 'text.document.type.key.'
            : 'text.document.type.key.Title.'
        const docLabelKeySuffix =
            documentType == 'SDOINVOICE'
                ? 'text.document.order.type.key.'
                : docLabelKey
        docType = i18nHelper
            .getI18nMessage(docLabelKeySuffix + documentType)
            .replace(/\s+/g, '-')
            .toLowerCase()
    }
    let file = {
        assetID: product || 'Undefined',
        fileName: 'Unavailable file',
        fileType: docType || 'Undefined',
        fileURL: window.location.href,
        siteLanguage: context.rootGetters['page/locale'],
        fileLanguage: languageCode || 'undefined'
    }

    let products = []
    let productData = {
        productID: context.rootState.product.webID || prodID,
        gmid: materialNumber
    }
    products.push(productData)

    await fetchProductAttributes(context, products)

    window.adobeDataLayer.push({
        event: FILE_DOWNLOAD_FAILED,
        file: file,
        productListItems: products
    })
}

function getLanguageCode() {
    var cookie = document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=')
        prev[name] = value.join('=')
        return prev
    }, {})

    return cookie.languageCode
}

function pageViewed(context) {
    document.addEventListener('click', function downloadAnalytics(e) {
        var link = e.target
        while (
            link &&
            !link.pathname &&
            link != document.body &&
            !link.hasAttribute('data-product-id')
        )
            link = link.parentElement

        var download = link && link.pathname && link.pathname.match(FILE_REGEX)
        if (download) {
            var type = download[2]
            var id, name, type

            if (download[1] == 'document-viewer.html') {
                var docViewer = link.search
                    .substring(1)
                    .split('&')
                    .reduce(function (dv, param) {
                        param = param.split('=')
                        if (param[0])
                            dv[param[0]] =
                                (param[1] &&
                                    decodeURI(param[1]).replace(/\+/g, ' ')) ||
                                true
                        return dv
                    }, {})

                id =
                    docViewer.code ||
                    docViewer.recordNumber ||
                    `${docViewer.product}|${docViewer.tradeProduct}`
                type = docViewer.contentType || docViewer.docType
                name = docViewer.title
            }

            //Language
            let docLanguage = link.dataset.docLanguage
            let siteLanguage = link.dataset.siteLanguage

            //Content Type
            let contentType = link.dataset.contentType

            //Product ID Array
            let productIds = link.dataset.productId
                .split(',')
                .filter(Boolean)
                .map((id) => {
                    return { productID: id }
                })

            if (~link.pathname.indexOf('/productdatasheet/')) type = 'tds'
            if (~link.pathname.indexOf('/safetydatasheet')) type = 'sds' // not sure if it is /safetydatasheet/ or /safetydatasheets/ I've found both versions in Hybris and that's why I've used /safetydatasheet - it covers both cases
            if (~link.pathname.indexOf('/regulatorydatasheet/')) type = 'rds'
            if (~link.pathname.indexOf('/compliance/')) type = 'compliance'
            if (/catalog-sel(ection-)?guide/.test(link.pathname))
                type = 'selection-guide'
            if (/app(lication)?-tech(nical)?-guide/.test(link.pathname))
                type = 'app-tech-guide'

            context.dispatch('download', {
                id: (id != '|' && id) || link.pathname,
                name: name || link.title || link.innerText || 'unknown title',
                type: (
                    contentType ||
                    type ||
                    el.contentType ||
                    'file'
                ).toLowerCase(),
                url: link.href,
                docLanguage: docLanguage || languageCode || 'undefined',
                language: siteLanguage,
                products: productIds
            })
        }
    })
}
