import { TruckIcon } from '../../../commerce/blocks/svg-elements/truck-icon/index';
import { CloseIcon } from '../../../commerce/blocks/svg-elements/close-icon/index';
import { inject } from 'vue';
export default {
  setup() {
    const deliveryTruckModal = inject('deliveryTruckModal');
    return {
      deliveryTruckModal
    };
  },
  components: {
    TruckIcon,
    CloseIcon
  },
  data() {
    return {
      isModalOpen: this.deliveryTruckModal.deliveryTruckLoggedInOpen,
      hideIcon: false
    };
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  mounted() {},
  methods: {
    openModal(focusOnClose = false) {
      this.deliveryTruckModal.deliveryTruckLoggedInOpenModal();
      document.body.classList.add('delivery-truck-modal-open');
      document.addEventListener('keydown', this.handleEscapeKeyPress);
      if (!focusOnClose) {
        document.addEventListener('keydown', this.handleFirstTab);
      }
      this.$nextTick(() => {
        const focusableElements = this.getFocusableElements();
        if (focusableElements.length > 0) {
          if (focusOnClose) {
            focusableElements[0].focus();
          }
          this.$refs.modal.addEventListener('keydown', this.handleTabOrder);
        }
      });
    },
    handleTabOrder(event) {
      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        const focusableElements = this.getFocusableElements();
        const index = focusableElements.indexOf(document.activeElement);
        if (index >= 0) {
          const nextElement = focusableElements[index + 1] || focusableElements[0];
          nextElement.focus();
        }
      }
    },
    closeModal() {
      this.deliveryTruckModal.deliveryTruckLoggedInCloseModal();
      document.body.classList.remove('delivery-truck-modal-open');
      document.removeEventListener('keydown', this.handleEscapeKeyPress);
      document.removeEventListener('keydown', this.handleFirstTab);
      this.$emit('onClose');
      if (this.triggerElement) {
        this.triggerElement.focus();
      }
    },
    handleEscapeKeyPress(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.closeModal();
      }
    },
    getFocusableElements() {
      const modal = this.$refs.modal;
      return Array.from(modal.querySelectorAll('button:not([disabled]), [role="button"], [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')).filter(el => el.style.visibility !== 'hidden' && getComputedStyle(el).visibility !== 'hidden' && (el.offsetWidth > 0 || el.offsetHeight > 0 || el.clientWidth > 0 || el.clientHeight > 0 || el === document.activeElement));
    },
    handleFirstTab(event) {
      if (event.key === 'Tab' || event.keyCode === 9) {
        event.preventDefault();
        const focusableElements = this.getFocusableElements();
        const nonCloseButtonElement = [...focusableElements].find(el => !el.classList.contains('closeDeliveryTruckModal'));
        nonCloseButtonElement?.focus();
        document.removeEventListener('keydown', this.handleFirstTab);
      }
    },
    hideCloseIcon(value) {
      this.hideIcon = value;
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.handleEscapeKeyPress);
      document.removeEventListener('keydown', this.handleFirstTab);
    }
  }
};