import CoreComponent from '../../../core/CoreComponent.vue';
import DeliveryTruckModal from '../modal/index.vue';
import { LoadingDots } from '../../../commerce/blocks/output-elements/loading-dots/index';
import { MultiSearchBar } from '../../../commerce/blocks/input-elements/multi-search-bar/index';
import { UserHelper } from '../../../../../helpers/user-helper';
import { AddressTable } from '../../../commerce/blocks/output-elements/address-table/index';
import Pagination from './pagination.vue';
import i18nHelper from '../../../commerce/myAccount/helper/i18n-helper.js';
import { PlusSignIcon } from '@/dow-platform/components/commerce/blocks/svg-elements/plus-sign-icon';
import { MinusSignIcon } from '@/dow-platform/components/commerce/blocks/svg-elements/minus-icon';
import { trapFocusToModal } from '@/utils/accessibility';
const INTERNAL_USER_SEARCH_OPTIONS = [{
  title: i18nHelper.getI18nMessage('customer.no.Title'),
  value: 'sapCustomerID',
  placeholder: i18nHelper.getI18nMessage('customer.no.Title'),
  minLength: 1
}, {
  title: i18nHelper.getI18nMessage('location.name.Title'),
  value: 'locName',
  placeholder: i18nHelper.getI18nMessage('location.name.Title'),
  minLength: 3
}];
const EXTERNAL_USER_SEARCH_OPTIONS = [{
  title: i18nHelper.getI18nMessage('location.name.Title'),
  value: 'locName',
  placeholder: i18nHelper.getI18nMessage('location.name.Title'),
  minLength: 3
}, {
  title: i18nHelper.getI18nMessage('customer.no.Title'),
  value: 'sapCustomerID',
  placeholder: i18nHelper.getI18nMessage('customer.no.Title')
}, {
  title: i18nHelper.getI18nMessage('postal.code.Title'),
  value: 'postalcode',
  placeholder: i18nHelper.getI18nMessage('postal.code.Title'),
  minLength: 3
}, {
  title: i18nHelper.getI18nMessage('city.Title'),
  value: 'town',
  placeholder: i18nHelper.getI18nMessage('city.Title'),
  minLength: 3
}, {
  title: i18nHelper.getI18nMessage('state'),
  value: 'region',
  placeholder: i18nHelper.getI18nMessage('state'),
  minLength: 3
}];
export default {
  extends: CoreComponent,
  data: function () {
    return {
      addressType: 'DELIVERY',
      selectedDeliveryAddressId: null,
      selectedCompanyAddressId: null,
      selectedCompanyAddressUid: null,
      userId: null,
      displayedAddresses: [],
      enableTable: false,
      truckReloadKey: 0,
      showAdvancedFilters: true,
      city: '',
      isClearSubmit: false,
      maxResults: 100,
      isNewUser: false,
      activeElement: null,
      hideTruck: UserHelper.hideTruckBanner()
    };
  },
  components: {
    DeliveryTruckModal,
    LoadingDots,
    MultiSearchBar,
    AddressTable,
    Pagination,
    PlusSignIcon,
    MinusSignIcon
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },
    loading: function () {
      return this.$store.state.loading.drawerLoading;
    },
    addresses: function () {
      return this.$store.getters['address/addresses'](this.addressType, this.addressType, 'delivery-truck');
    },
    addressesFiltered: function () {
      return this.$store.getters['address/addressesFiltered'](this.addressType);
    },
    hasCartItems: function () {
      return this.$store.state.user?.dccUserInfo?.data?.numberOfCartItems > 0 || this.$store.state.user?.dccUserInfo?.data?.numberOfSampleCartItems > 0;
    },
    searchOptions: function () {
      return this.isInternalUser ? INTERNAL_USER_SEARCH_OPTIONS : EXTERNAL_USER_SEARCH_OPTIONS;
    },
    canSubmit: function () {
      return this.selectedDeliveryAddressId && this.selectedCompanyAddressId && this.selectedCompanyAddressUid;
    },
    formattedData: function () {
      const delAddress = this.selectedDeliveryAddressId;
      const compAddress = this.selectedCompanyAddressId;
      const compAddressUid = this.selectedCompanyAddressUid;
      return `shipTo=${delAddress}&soldToUid=${compAddressUid}&soldToCustomerNumber=${compAddress}`;
    },
    contactSupportText: function () {
      return this.getLabel('truck.modal.textNoItemsInCart').replace('{0}', 'support-link');
    },
    defaultAddressSelected: function () {
      return UserHelper.hasDefaultDeliveryAddress();
    },
    isInternalUser: function () {
      return UserHelper.isInternalUser();
    }
  },
  created() {
    this.setup();
  },
  watch: {
    '$store.state.user.isAuthenticated': function (loggedIn) {
      this.hideTruck = UserHelper.hideTruckBanner();
      if (!loggedIn || UserHelper.hideTruckBanner()) {
        this.removeEventListeners();
      } else {
        this.addEventListeners();
      }
    }
  },
  methods: {
    setup: function () {
      this.$store.dispatch('user/isLoggedIn').then(() => {
        if (this.$store.state.user.isAuthenticated && !UserHelper.hideTruckBanner()) {
          this.selectedCompanyAddressId = +UserHelper.getUserData()?.selectedSoldToId || null;
          this.selectedDeliveryAddressId = +UserHelper.getUserData()?.selectedShipToId || null;
          if (!this.isInternalUser && this.addresses.length <= 1) {
            this.$store.dispatch('address/getParentAddresses', {
              addressType: this.addressType,
              isOrderBlocked: true
            });
            this.enableTable = true;
          }
        }
      });
    },
    getLabel: function (key, value) {
      return !!i18nHelper.getI18nMessage(key, value) ? i18nHelper.getI18nMessage(key, value) : key;
    },
    showDeliveryAddressConfirmModal() {
      this.activeElement = document.activeElement;
      let modal = document.querySelector('#confirmAddressChange');
      $('#confirmAddressChange').addClass('visible');
      modal?.querySelector('input:not([type="hidden"]), button, a, textarea')?.focus();
      modal.addEventListener('keydown', function (e) {
        trapFocusToModal(e, modal);
      });
    },
    closeDeliveryAddressConfirmModal() {
      this.activeElement = document.activeElement;
      let modal = document.querySelector('#confirmAddressChange');
      $('#confirmAddressChange').removeClass('visible');
      modal?.querySelector('input:not([type="hidden"]), button, a, textarea')?.focus();
      modal.addEventListener('keydown', function (e) {
        trapFocusToModal(e, modal);
      });
    },
    getHeaderTitle: function () {
      return this.isNewUser ? this.getLabel('truck.modal.firstTitle') : this.getLabel('delivery.address.Title');
    },
    searchIfPossible: function () {
      const searchButton = this.cmpEl.querySelector('.search__btn');
      if (searchButton && !searchButton.hasAttribute('disabled')) {
        searchButton.dispatchEvent(new Event('click'));
      }
    },
    onSearch: function (value) {
      const filterAttributes = {
        searchOptionKey: value.attribute,
        searchOptionValue: value.value,
        searchAdvancedValue: this.showAdvancedFilters && this.isInternalUser ? this.city : null
      };
      if (!value.value) {
        this.clearModalData();
      }
      this.$store.dispatch('address/getFilteredDeliveryAddresses', {
        addressType: this.addressType,
        addressSearchType: this.addressType,
        filterAttributes,
        isOrderBlocked: true
      }).then(() => {
        this.expandGlobalSelectForSearch();
        this.enableTable = true;
      }).catch(() => {
        console.log('Error searching for addresses.');
      });
    },
    onSubmit: function () {
      if (this.canSubmit) {
        const quickOrderPage = document.querySelector('#quickOrder');
        let isEmptyCart = this.$store.state.user?.dccUserInfo?.data?.numberOfCartItems == 0;
        if (quickOrderPage && !isEmptyCart) {
          const confirmAddressChangeModal = document.querySelector('#confirmAddressChange');
          const confirmAddressChangeButton = confirmAddressChangeModal.querySelector('.button-add');
          document.querySelector('.delivery-truck-modal').style.visibility = 'hidden';
          this.showDeliveryAddressConfirmModal();
          confirmAddressChangeButton.addEventListener('click', () => {
            this.closeDeliveryAddressConfirmModal();
            document.querySelector('.delivery-truck-modal').style.visibility = 'unset';
            this.$store.dispatch('address/submitDelivery', {
              userId: UserHelper.getUserId(),
              formattedData: this.formattedData,
              selectedSoldTo: this.selectedCompanyAddressId
            }).then(() => {
              this.closeTruckModal();
            }).catch(() => {
              console.log('Error saving address');
              this.closeTruckModal();
            });
          });
        } else {
          this.$store.dispatch('address/submitDelivery', {
            userId: UserHelper.getUserId(),
            formattedData: this.formattedData,
            selectedSoldTo: this.selectedCompanyAddressId
          }).then(() => {
            this.closeTruckModal();
          }).catch(() => {
            console.log('Error saving address');
            this.closeTruckModal();
          });
        }
      }
    },
    onSelectCompanyAddress: function (event) {
      this.selectedCompanyAddressId = event.addressId;
      this.selectedCompanyAddressUid = event.uid;
    },
    expandGlobalSelectForSearch: function () {
      this.selectedCompanyAddressId = +UserHelper.getUserData()?.selectedSoldToId || null;
      this.selectedDeliveryAddressId = +UserHelper.getUserData()?.selectedShipToId || null;
      let globalSelect = this.addresses.find(address => address.addressId === this.selectedDeliveryAddressId);
      if (globalSelect && globalSelect.hasSubAddresses) {
        this.$store.dispatch('address/getSubAddresses', {
          addressType: this.addressType,
          parentId: this.selectedDeliveryAddressId
        });
      }
    },
    openTruckModal(focusOnClose = false) {
      this.selectedCompanyAddressId = +UserHelper.getUserData()?.selectedSoldToId || null;
      this.selectedDeliveryAddressId = +UserHelper.getUserData()?.selectedShipToId || null;
      if (!this.isInternalUser) {
        if (this.addresses.length <= 1) {
          this.$store.dispatch('address/getParentAddresses', {
            addressType: this.addressType,
            isOrderBlocked: true
          }).then(() => {
            this.expandGlobalSelectForSearch();
          });
        } else {
          this.expandGlobalSelectForSearch();
        }
        this.enableTable = true;
      }
      this.$refs.deliveryTruckLoggedInModal?.openModal(focusOnClose);
    },
    closeTruckModal() {
      this.clearModalData();
      this.$refs.deliveryTruckLoggedInModal.closeModal();
      this.$refs.deliveryTruckLoggedInModal.hideCloseIcon(false);
      this.isNewUser = false;
    },
    clearModalData() {
      this.$store.dispatch('address/clearDeliveryAddresses');
      this.enableTable = false;
      this.selectedDeliveryAddressId = null;
      this.selectedCompanyAddressId = null;
      this.selectedCompanyAddressUid = null;
      this.city = '';
    },
    addEventListeners() {
      const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal');
      if (deliveryTruckButtons) {
        deliveryTruckButtons.forEach(button => {
          button.addEventListener('click', this.openModalWithFalse);
          button.addEventListener('keydown', this.handleKeydown);
        });
      }
    },
    removeEventListeners() {
      const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal');
      if (deliveryTruckButtons) {
        deliveryTruckButtons.forEach(button => {
          button.removeEventListener('click', this.openModalWithFalse);
          button.removeEventListener('keydown', this.handleKeydown);
        });
      }
    },
    setClearSubmit(value) {
      this.isClearSubmit = value;
      if (value === true) {
        this.clearModalData();
        this.selectedCompanyAddressId = +UserHelper.getUserData()?.selectedSoldToId || null;
        this.selectedDeliveryAddressId = +UserHelper.getUserData()?.selectedShipToId || null;
      }
    },
    openModalWithFalse() {
      this.openTruckModal(false);
    },
    handleKeydown(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.openTruckModal(true);
      }
    }
  },
  mounted() {
    this.$store.dispatch('user/isLoggedIn').then(() => {
      if (this.$store.state.user.isAuthenticated && !UserHelper.hideTruckBanner()) {
        this.addEventListeners();
        if (!this.defaultAddressSelected) {
          const deliveryTruckButtons = document.querySelectorAll('.cmp--header_truck-modal');
          if (deliveryTruckButtons) {
            deliveryTruckButtons[0].click();
          }
          this.isNewUser = true;
          this.$refs.deliveryTruckLoggedInModal.hideCloseIcon(true);
        }
      }
    });
  }
};