import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DeliveryTruckModal = _resolveComponent("DeliveryTruckModal");
  return _openBlock(), _createBlock(_Teleport, {
    to: ".cmp--header"
  }, [$data.hideTruck ? (_openBlock(), _createBlock(_component_DeliveryTruckModal, {
    key: 0,
    ref: "deliveryTruckLoggedOutModal",
    title: $options.getLabel('choose.location')
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      innerHTML: $data.content
    }, null, 8, _hoisted_1)]),
    _: 1
  }, 8, ["title"])) : _createCommentVNode("", true)]);
}