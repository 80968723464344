import { ChevronIcon } from '../../svg-elements/chevron-icon'
import { CloseIcon } from '../../svg-elements/close-icon'
//import SearchValidator from '../../../../../../helpers/search-validator-helper';
import i18nHelper from '../../../myAccount/helper/i18n-helper'
import { UserHelper } from '@/helpers/user-helper'
import randomId from '@/helpers/randomId'

export const MultiSearchBar = {
    template: `<div class="multi-search-bar" :class="{'has-search-button': showSearchButton, inverted: invert}">
            <div class="search-type-select" v-if="!customSelectList">
                <span>{{ selectedOption.title }}</span>
                <select ref="selectDropdown" :aria-label="selectLabel" @change="onDropdownSelect($event)" v-model="selectedOptionValue">
                    <option v-for="type in searchOptions" tabindex="0" :value="type.value" :aria-label="type.ariaLabel || type.title">{{ type.title }}</option>
                </select>
                <ChevronIcon></ChevronIcon>
            </div>

            <div class="search-type-custom-select" v-if="customSelectList">
                <div class="search-type-custom-select__select" :aria-label="searchByLabel" role="combobox" :aria-expanded="showDropdown" :aria-controls="'dropdown-' + menuId" tabindex="0" @keydown="handleKeydown" ref="dropdownButton">
                    <div @click="toggleDropdown()"
                        :class="['search-type-custom-select__trigger', {'search-type-custom-select__trigger--open': showDropdown}]">
                        <span>{{ selectedOption.title }}</span>
                        <ChevronIcon></ChevronIcon>
                    </div>
                    <div class="search-type-custom-select__options" ref="dropdownMenu" :id="'dropdown-' + menuId" v-show="showDropdown" role="listbox">
                        <div v-for="type in searchOptions" :key="type.value" tabindex="0" role="option" @click.prevent="selectOption($event, type)" @keydown.enter.prevent="selectOption($event, type)" @keydown.space.prevent="selectOption($event, type)"
                            :class="['search-type-custom-select__option', { 'search-type-custom-select__option-selected': type.value === selectedOptionValue }]"
                            :aria-selected="type.value === selectedOptionValue"
                        >
                            {{ type.title }}
                        </div>
                    </div>
                </div>
            </div>

			<div class="select-main-input">
				<form @submit.prevent="onSubmit" autocomplete="off" ref="searchForm">
					<input v-model="selectedValue" ref="searchBar" tabindex="0" :aria-label="searchLabel" name="searchType" type="text" :placeholder="selectedOption.placeholder">
					<p class="errorMessage" v-if="errorMessage">{{ errorMessage }}</p>
				</form>
				<svg :class="{isDisabledSvg: disabledSvg}" v-if="!showSearchButton" role="button" @click.prevent="triggerFormSubmit" :aria-label="searchLabel" id="searchSvg"><use xmlns:xlink="http://www.w3.org/2000/xlink" href="#search-icon"></use></svg>
				<div class="divider"></div>
				<CloseIcon :class="{isDisabledSvg: disabledSvg}" class="search__close" tabindex="0" type="button" aria-label="clear search" @click="clearSearchTerm"></CloseIcon>
			</div>
            <button class="cmp-button cmp-button--filled-red cmp-button--lg search__btn" :disabled="disabledSvg" v-if="showSearchButton" @click="triggerFormSubmit" :aria-label="searchLabel">{{searchButton}}</button>
        </div>`,
    mixins: [randomId],
    props: {
        searchOptions: {
            type: Array,
            required: true
        },
        invert: {
            type: Boolean,
            required: false
        },
        defaultSearchOptionValue: {
            type: String,
            required: false,
            default: ''
        },
        defaultSearchOptionIndex: {
            type: Number,
            required: false,
            default: 0
        },
        showSearchButton: {
            type: Boolean,
            required: false,
            default: false
        },
        customSelectList: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedAddressType: {
            type: String,
            required: false,
            default: ''
        },
        selectedAddress: {
            type: String,
            required: false,
            default: ''
        },
        clearAddressFilter: {
            type: Boolean,
            required: false,
            default: false
        },
        module: {
            type: String,
            required: false,
            default: ''
        },
        disableAdvancedSearch: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ChevronIcon,
        CloseIcon
    },
    data: function () {
        return {
            selectedOption: this.searchOptions[this.defaultSearchOptionIndex],
            selectedOptionValue:
                this.searchOptions[this.defaultSearchOptionIndex].value,
            searchLabel: i18nHelper.getI18nMessage('button.search.Title'),
            searchButton: i18nHelper.getI18nMessage('button.search.UPPER'),
            selectLabel: i18nHelper.getI18nMessage('searchOption.ariaLabel'),
            searchByLabel: i18nHelper.getI18nMessage('search.by'),
            errorMessage: '',
            selectedValue: this.defaultSearchOptionValue,
            showDropdown: false,
            isClearSubmit: false,
            arrowKeyIndex: -1,
            menuId: this.randomId()
        }
    },
    mounted: function() {
        if (this.searchOptions.length > 1) {
            this.$refs?.dropdownButton?.addEventListener('keydown', (event) => {
                this.handleKeyboardFocus(event)
            })
        }
    },
    unmounted: function() {
        if (this.searchOptions.length > 1) {
            this.$refs?.dropdownButton?.removeEventListener('keydown', (event) => {
                this.handleKeyboardFocus(event)
            })
        }
    },
    watch: {
        showDropdown: function(newValue, oldValue) {
            if (!newValue)
                this.arrowKeyIndex = -1
        }
    },
    computed: {
        disabledSvg: function () {
            const minimimSearchLength = this.selectedOption.minLength || 1
            return !(this.selectedValue.length >= minimimSearchLength) || this.disableAdvancedSearch
        }
    },
    methods: {
        onSubmit() {
            if (this.disabledSvg && !this.isClearSubmit) {
                return
            }

            this.errorMessage = ''
            const cleanValue = this.selectedValue.trim()
            const isValidInput = this.selectedOption.validator
                ? this.selectedOption.validator(cleanValue)
                : true
            const searchValue = {
                attribute: this.selectedOption.value,
                value: cleanValue
            }

            if (isValidInput) {
                this.$emit('select-search', searchValue)
            } else {
                this.errorMessage = this.selectedOption.errorMessage
            }

            this.isClearSubmit = false
            this.$emit('is-clear-submit', false)
        },
        triggerFormSubmit(isClearSubmit) {
            if (this.disabledSvg && !isClearSubmit) {
                return
            }
            
            if (
                this.clearAddressFilter &&
                UserHelper.isInternalUser() &&
                typeof isClearSubmit === 'boolean' &&
                isClearSubmit
            ) {
                this.$store.commit('address/clearUnappliedAddresses', {
                    addressType: this.selectedAddressType,
                    selectedValue: this.selectedAddress
                })
                this.$emit('is-clear-submit', true)
            } else if (
                this.selectedValue.length > 0 ||
                (typeof isClearSubmit === 'boolean' && isClearSubmit)
            ) {
                this.isClearSubmit = true
                this.onSubmit({
                    target: {
                        searchType: this.$refs.searchForm[0]
                    }
                })
            }
        },
        onDropdownSelect(event) {
            this.selectedOption = this.searchOptions.find(
                (t) => t.value === event.target.value
            )
        },
        clearSearchTerm() {
            this.selectedOption = this.searchOptions[0]
            this.selectedOptionValue = this.searchOptions[0].value
            this.selectedValue = ''
            if (this.module != 'coa') {
                this.$nextTick(() => {
                    if(this.$refs.selectDropdown) {
                        this.$refs.selectDropdown.selectedIndex = 0
                    }
                })
                this.triggerFormSubmit(true)
            }
            this.$emit('clear-advanced-search')
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },
        selectOption(event, type) {
            this.selectedOption = type
            this.selectedOptionValue = type.value
            this.toggleDropdown()

            if (event.code === 'Space' || event.key === 'Enter') {
                event.preventDefault()
                this.$refs.searchBar.focus()
            }
        },
        handleKeydown(e) {
            if (document.activeElement == this.$refs.dropdownButton && e.code === 'Tab' && !e.shiftKey) {
                e.preventDefault()
                if (this.showDropdown)
                    this.toggleDropdown()
                this.$refs.searchBar.focus()
            } else if (document.activeElement == this.$refs.dropdownButton && (e.key === 'Enter' || e.code === 'Space')) {
                e.preventDefault()
                this.toggleDropdown()
                this.$refs.dropdownButton.focus()
            }
        },
        closeDropdown() {
            this.showDropdown = false
        },
        handleKeyboardFocus: function(event) {
            this.$nextTick(() => {
                let focused = document.activeElement
                var options = this.$refs.dropdownMenu?.querySelectorAll('.search-type-custom-select__option')
                if (focused == this.$refs.dropdownButton || Array.from(options).includes(focused)) {
                    if (event.key === 'Tab') {
                        this.arrowKeyIndex = -1
                        if (this.showDropdown)
                            this.toggleDropdown()
                    }
                    if (event.key === 'ArrowDown') {
                        event.preventDefault()
                        this.arrowKeyIndex = this.arrowKeyIndex + 1
                        if (this.arrowKeyIndex > options.length - 1)
                            this.arrowKeyIndex = 0
                        options[this.arrowKeyIndex].focus()
                    }
                    if (event.key === 'ArrowUp') {
                        event.preventDefault()
                        this.arrowKeyIndex = this.arrowKeyIndex - 1
                        if (this.arrowKeyIndex < 0)
                            this.arrowKeyIndex = options.length - 1
                        options[this.arrowKeyIndex].focus()
                    }
                    if (event.key === 'Escape') {
                        this.showDocuments = false
                        this.arrowIconClass = ''
                    }
                }
            })
        }
    },
    watch: {
        selectedValue(newVal) {
            if (newVal.length <= 1) {
                this.$emit('clear-advanced-search')
            } else {
                this.$emit('input-change', newVal)
            }
        },
        disabledSvg(newVal) {
            this.$emit('search-disabled', newVal)
        }
    }
}
