import i18nHelper from '../../../commerce/myAccount/helper/i18n-helper.js';
import Dropdown from './dropdown.vue';
const PAGE_SIZE_OPTIONS = [{
  id: 'pageSizeOption1',
  title: 5,
  value: 5
}, {
  id: 'pageSizeOption2',
  title: 10,
  value: 10
}, {
  id: 'pageSizeOption3',
  title: 25,
  value: 25
}, {
  id: 'pageSizeOption4',
  title: i18nHelper.getI18nMessage('all.Title'),
  value: -1
}];
export default {
  props: ['items'],
  components: {
    Dropdown
  },
  data: function () {
    return {
      pageSize: 5,
      currentPage: 0
    };
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.items.length / this.pageSize);
    },
    displayedItems: function () {
      return this.isDisplayAll ? this.items : this.pages[this.currentPage];
    },
    pages: function () {
      return this.createPages(this.items, this.pageSize);
    },
    selectedPageSizeOptionIndex: function () {
      return this.pageSizeOptions.findIndex(option => option.value === this.pageSize);
    },
    pageOptions: function () {
      return new Array(this.totalPages).fill('').map((_, i) => {
        return {
          id: `pageOption${i}`,
          title: i + 1,
          value: i
        };
      });
    },
    showPagination: function () {
      return this.items && this.items.length > 5;
    },
    pageSizeOptions: function () {
      let addShowAllOption = false;
      return PAGE_SIZE_OPTIONS.filter(option => {
        if (option.value === -1) {
          return addShowAllOption;
        } else if (option.value < this.items.length) {
          addShowAllOption = true; // At least one other page size option available
          return true;
        } else {
          return false;
        }
      });
    },
    isDisplayAll: function () {
      return this.pageSize === -1;
    },
    isFirstPage: function () {
      return this.currentPage === 0;
    },
    isLastPage: function () {
      return this.currentPage === this.totalPages - 1;
    }
  },
  watch: {
    displayedItems: {
      immediate: true,
      handler(items) {
        this.$emit('change-items', items || []);
      }
    },
    items: {
      immediate: true,
      handler() {
        this.pageSize = 5;
        this.currentPage = 0;
      }
    },
    totalPages: {
      immediate: true,
      handler() {
        this.resetCurrentPage();
      }
    }
  },
  methods: {
    onPageChange: function (val) {
      this.currentPage = this.currentPage + val;
    },
    onChangePageSize: function (pageSizeOption) {
      this.pageSize = pageSizeOption.value;
      this.currentPage = 0;
    },
    createPages: function (items, groupSize) {
      const numOfGroups = Math.ceil(items.length / groupSize);
      return new Array(numOfGroups).fill('').map((_, i) => items.slice(i * groupSize, (i + 1) * groupSize));
    },
    resetCurrentPage: function () {
      this.currentPage = 0;
    },
    getLabel: function (key) {
      return i18nHelper.getI18nMessage(key);
    }
  }
};