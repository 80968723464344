import i18nHelper from '@/dow-platform/components/commerce/myAccount/helper/i18n-helper'

export const NavMenu = {
    name: 'NavMenu',
    template: `
		<template v-if="isUserLoggedIn">
			<li v-for="opt in opts" class="cmp--header_my-account-modal_option">
				<span @click="toggleMyAccountDropdown">
					<a tabindex="0" v-bind="opt.attrs" v-on="opt.handlers"><span :class="opt.exclamatoryIcon ? 'exclamatory-icon' : '' ">{{ opt.label }}</span>
                    <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cmp--header_header-my-account-modal_option_svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.70711 0.792938L0 4.50005L3.70711 8.20715L5.12132 6.79294L2.82843 4.50005L5.12132 2.20715L3.70711 0.792938Z"
                      fill="#E80033"
                    />
                  </svg>
                    </a>
				</span>
			</li>
		</template>	
		`,
    props: {
        isUserLoggedIn: Boolean
    },
    computed: {
        userInfo: function () {
            return this.$store.state.user.dccUserInfo
                ? this.$store.state.user.dccUserInfo
                : {}
        },
        reauthorizationRequired: function () {
            return this.$store.state.user.dccUserInfo?.data
                ?.reauthorizationRequired
        },
        options: function () {
            let userInfo = this.userInfo.data
            if (userInfo) {
                let accountLinks = this.buildAccountLinks(
                    userInfo.accountDropDownLinks
                )
                let dropdown = []

                if (this.reauthorizationRequired) {
                    const confirmAccountLabel = i18nHelper.getI18nMessage(
                        'reauth.confirm.acct.mixcase'
                    )
                    dropdown.push({
                        key: 'reauth.confirm.acct.mixcase', 
                        label: confirmAccountLabel,
                        exclamatoryIcon: true,
                        handlers: {
                            click: this.triggerReauth
                        }
                    })

                    document
                        .querySelector('.cmp--header_my-account p')
                        .classList.add('exclamatory-icon')
                } else {
                    document
                        .querySelector('.cmp--header_my-account p')
                        .classList.remove('exclamatory-icon')
                }

                if (accountLinks) {
                    Object.keys(accountLinks).forEach(function (label) {
                        let key = accountLinks[label].key
                        let name = accountLinks[label].value
                        let link = accountLinks[label].link
                        if (key==="order.management.Title" || key==="myaccount.myItems"
                            || key==="myaccount.resources" || key==="my.profile.Title" || key==="myaccount.quickOrder"){
                                dropdown.push({
                                    key: key, 
                                    label: name,
                                    attrs: {
                                        href: link
                                    }
                                })
                            }
                    })
                }
                
                let dropdownSequence = [
                    {key: 'reauth.confirm.acct.mixcase'}, 
                    {key: 'myaccount.dashboard'}, 
                    {key: 'order.management.Title'}, 
                    {key: 'myaccount.myItems'}, 
                    {key: 'myaccount.resources'}, 
                    {key: 'my.profile.Title'}, 
                    {key: 'myaccount.quickOrder'}
                ]

                let newDropdown = dropdown.toSorted((a, b) => {
                    return (dropdownSequence.findIndex((i) => i.key === a.key) - dropdownSequence.findIndex((i) => i.key === b.key))
                })

                return newDropdown
            }
        },
        opts: function () {
            const modifiers = ['header', 'separator', 'disabled', 'arrow']

            return this.options?.map((opt) => {
                opt.attrs = opt.attrs || {}
                opt.attrs.class = opt.attrs.class || {}
                modifiers.forEach(
                    (modifier) =>
                        (opt.attrs.class['nav-menu__' + modifier] =
                            opt[modifier])
                )
                return opt
            })
        }
    },
    methods: {
        toggleMyAccountDropdown: function () {
            if (window.innerWidth > 1024) {
                document.getElementsByClassName('cmp--header_my-account')[0].click()
            } else {
                document.querySelector('.cmp-mega-menu-navigation button#nav-trigger.cmp-mega-menu-navigation-hamburger').click()
            }
        },
        buildAccountLinks: function (links) {
            let accountLinks
            let locale =
                this.$store.state.browser.cookies['languageCode'] || 'en-us'
            if (links) {
                accountLinks = links.reduce((acc, cur) => {
                    const mappedi18nTags = Object.entries(cur).reduce(
                        (linkObjects, [i18nTag, linkObj]) => {
                            let i18nLabel = ''
                            if (i18nTag === 'myaccount.invoiceManagement' || i18nTag === 'support.center') {
                                i18nLabel = i18nHelper.getI18nMessage('order.management.Title')
                                    linkObj = `/${locale}${linkObj}`
                            } else {
                                i18nLabel = i18nHelper.getI18nMessage(i18nTag)
                                    linkObj = `/${locale}${linkObj}`
                            }
                            return { key: i18nTag, value: i18nLabel, link: linkObj }
                        },
                        []
                    )
                    return acc.concat(mappedi18nTags)
                }, [])
            }
            if (accountLinks.some((link) => { return link['key'] === 'order.management.Title' })) {
                return accountLinks
            } else if (accountLinks.some((link) => { return link['key'] === 'myaccount.invoiceManagement' })) {
                let invoiceIndex = accountLinks.findIndex((link) => { return link['key'] === 'myaccount.invoiceManagement' })
                accountLinks[invoiceIndex] = { key: 'order.management.Title', value: accountLinks[invoiceIndex].value, link: accountLinks[invoiceIndex].link }
                return accountLinks
            } else if (accountLinks.some((link) => { return link['key'] === 'support.center' })) {
                let supportIndex = accountLinks.findIndex((link) => { return link['key'] === 'support.center' })
                accountLinks[supportIndex] = { key: 'order.management.Title', value: accountLinks[supportIndex].value, link: accountLinks[supportIndex].link }
                return accountLinks
            } else {
                return accountLinks
            }
        },
        triggerReauth: function (event) {
            event.preventDefault()
            this.$emit('reauth-required')
        }
    }
}
