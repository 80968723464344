import i18nHelper from '../../../myAccount/helper/i18n-helper.js'
export const AddressTable = {
    name: 'AddressTable',
    template: `<table class="address-table">
        <thead>
            <tr>
                <th></th>
                <th>{{getLabel('account.myItems.myPartNumbers.list.CustNumber')}}</th>
                <th>{{getLabel('account.myItems.myPartNumbers.list.DeliveryAddress')}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="sortedAddresses.length !== 0" v-for="(address) in sortedAddresses" :key="address.addressId">
                <td>
                    <input type="radio"
                        name="address"
                        :id="'address-' + address.addressId"
                        :value="address.addressId"
                        :checked="selectedParentId == address.addressId"
                        @change="onSelectParentAddress(address)"
                        >
                </td>
                <td id="addressId">{{address.addressId}}</td>
                <td><span id="addressName">{{address.companyName}}</span><br>
                    <span id="formattedAddress">{{address.formattedAddress}}</span>
                    <div v-if="address.hasSubAddresses && address.subAddresses.length > 1" :class="'sub-addresses-address-'+ address.addressId">
                        <table class="subAddressTable">
                            <tbody>
                                <tr><th class="subAddressTitle" colspan="3">{{getLabel('select.company.address.Title')}}</th></tr>
                                <tr class="subAddresses" v-for="(subAddress) in sortedSubAddresses(address)" :key="subAddress.addressId">
                                    <td><input type="radio"
                                        name="subAddress"
                                        :id="'subAddress-' + subAddress.addressId"
                                        :value="subAddress.addressId"
                                        :checked="(selectedChildId == subAddress.addressId) && (selectedParentId == address.addressId)"
                                        @change="onSelectChildAddress(subAddress, address)">
                                    </td>
                                    <td><span id="addressId">{{subAddress.addressId}}</span></td>
                                    <td><span id="addressName">{{subAddress.companyName}}</span><br><span id="formattedAddress">{{subAddress.formattedAddress}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            <tr class="noResults" v-else-if="!isClearSubmit"><td colspan="3">{{getLabel('no.results.found.Sentence')}}</td></tr>
        </tbody>
    </table>`,
    props: {
        addressType: {
            type: String,
            required: true
        },
        addresses: {
            required: true
        },
        selectedParentId: {
            required: true
        },
        selectedChildId: {
            required: true
        },
        isClearSubmit: {
            type: Boolean,
            required: false
        }
    },
    data: function () {
        return {
            globalSelection: this.selectedParentId
        }
    },
    methods: {
        getLabel: function (key) {
            return !!i18nHelper.getI18nMessage(key)
                ? i18nHelper.getI18nMessage(key)
                : key
        },
        getSubAddresses(parentAddress, selectDefault = false) {
            if (parentAddress.subAddresses.length <= 1) {
                this.$store
                    .dispatch('address/getSubAddresses', {
                        addressType: this.addressType,
                        parentId: parentAddress.addressId
                    })
                    .then(() => {
                        let updatedParent = this.addresses.find(
                            (address) =>
                                address.addressId === parentAddress.addressId
                        )
                        let subAddress =
                            updatedParent.subAddresses.find(
                                (address) =>
                                    address.addressId ===
                                    updatedParent.addressId
                            ) || this.sortedSubAddresses(updatedParent)[0]
                        this.handleSubAddressSelect(subAddress)
                    })
            }
        },
        sortedSubAddresses: function (address) {
            return address.subAddresses.slice().sort((a, b) => {
                return (a.companyName || '').localeCompare(b.companyName || '')
            })
        },
        onSelectParentAddress(parentAddress) {
            this.handleParentAddressSelect(parentAddress)
            if (parentAddress.hasSubAddresses) {
                if (parentAddress.subAddresses.length <= 1) {
                    this.getSubAddresses(parentAddress, true)
                } else {
                    let updatedParent = this.addresses.find(
                        (address) =>
                            address.addressId === parentAddress.addressId
                    )
                    let subAddress =
                        updatedParent.subAddresses.find(
                            (address) =>
                                address.addressId === updatedParent.addressId
                        ) || this.sortedSubAddresses(updatedParent)[0]
                    this.handleSubAddressSelect(subAddress)
                }
            } else {
                this.handleSubAddressSelect(parentAddress.subAddresses[0])
            }
        },
        onSelectChildAddress(subAddress, parentAddress) {
            this.handleSubAddressSelect(subAddress)
            if (this.selectedParentId !== parentAddress.addressId) {
                this.handleParentAddressSelect(parentAddress)
            }
        },
        handleParentAddressSelect(address) {
            this.$emit('update:selectedParent', address.addressId)
        },
        handleSubAddressSelect(address) {
            const payload = {
                addressId: address.addressId,
                uid: address.uid
            }
            this.$emit('update:selectedChild', payload)
        }
    },
    computed: {
        sortedAddresses() {
            return this.addresses.slice().sort((a, b) => {
                return (a.companyName || '').localeCompare(b.companyName || '')
            })
        }
    }
}
