import { escapeHtml } from '../../../utils/html'
// dataLayer events
export const CMP_LOADED = 'cmp:loaded'
export const CMP_CLICK = 'cmp:click'
export const CMP_SHOW = 'cmp:show'
export const CMP_SHOW_VIRTUAL = 'cmp:show:virtual'
export const CMP_HIDE = 'cmp:hide'
export const SEARCH_SUCCESS = 'search:success'
export const SEARCH_FAILURE = 'search:failure'
export const ADD_TO_CART = 'cart:add'
export const FILE_DOWNLOAD = 'file:download'
export const TABVIEW = 'tabview'

export class ACDLHandler {
    constructor(event, cmpId, cb, once = false) {
        this.event = event
        this.cmpId = cmpId
        this.cb = cb
        this.once = once
    }

    get eventName() {
        return getEventName(this.event)
    }

    get path() {
        return getComponentPath(this.cmpId)
    }

    get id() {
        return `${this.eventName}::${this.path}`
    }

    get handler() {
        this.handlerFn =
            this.handlerFn ||
            ((event) => {
                if (event?.eventInfo?.path == this.path) {
                    if (this.once) this.off()
                    this.cb(event)
                }
            })

        return this.handlerFn
    }

    on() {
        ACDLHandler.registry[this.id] = this
        window.adobeDataLayer.push((dl) =>
            dl.addEventListener(this.eventName, this.handler)
        )
    }

    off() {
        delete ACDLHandler.registry[this.id]
        window.adobeDataLayer.push((dl) =>
            dl.removeEventListener(this.eventName, this.handler)
        )
    }
}

ACDLHandler.registry = {}

export function getEventName(event) {
    event = event.type || event
    switch (event) {
        case 'click':
            return CMP_CLICK
        case 'show':
            return CMP_SHOW
        case 'show virtual':
            return CMP_SHOW_VIRTUAL
        case 'hide':
            return CMP_HIDE
        case 'loaded':
            return CMP_LOADED
        case 'Search Success':
            return SEARCH_SUCCESS
        case 'Search Failure':
            return SEARCH_FAILURE
        case 'Add to Cart':
            return ADD_TO_CART
        default:
            return event
    }
}

export function getComponentPath(cmpId) {
    return `component.${cmpId}`
}

export function getRandomComponentName(prefixName = '') {
    var random = Math.random() * 1000000000
    random = Math.floor(random)
    var compName = prefixName + random
    return compName
}

export function updateComponentDataLayer(cmpId, updates) {
    let state = window.adobeDataLayer.getState(getComponentPath(cmpId))

    if (state) {
        let data = { ...state, ...updates }
        window.adobeDataLayer.push({ component: { [cmpId]: data } })
    } else {
        window.adobeDataLayer.push({ component: { [cmpId]: updates } })
    }
}

export function searchTracking(search) {
    //create pseudo-component
    var compName = getRandomComponentName('search-')
    var retVal = getComponentPath(compName)

    updateComponentDataLayer(compName, {
        '@type': 'analytics/pseudo/search',
        searchDetails: search
    })

    //push event with the path referencing the pseudo-component
    window.adobeDataLayer.push({
        event:
            search.searchCount == 0
                ? getEventName('Search Failure')
                : getEventName('Search Success'),
        eventInfo: {
            path: retVal
        }
    })
}

// File download. Check if the clickedElement's href attribute ends with a file extension indicating a download
export function detectFileDownloadLink(cmpEl) {
    const clickHandler = (event) => {
        const button = event.target.closest('a')
        if (button && /\.(pdf|doc|docx|xls|xlsx)$/i.test(button.href)) {
            event.stopPropagation()
            let fileName = button.href
                .split('/')
                .pop()
                .split('?')[0]
                .split('#')[0]
            let file = {
                fileName: fileName,
                fileType: 'other',
                fileURL: button.href
            }
            fileDownloadTracking(file)
        }
    }
    cmpEl.addEventListener('click', clickHandler, true)
}

export function trackTabView(tab) {
    window.adobeDataLayer.push({
        event: CMP_SHOW,
        eventInfo: {
            path: `component.${tab}`
        }
    })
}

//update image component dataLayer data with dc:title
export function updateImageCmp() {
    const images = document.querySelectorAll('.cmp-image img')
    images.forEach((img) => {
        const imageName = escapeHtml(img.alt, true)
        const componentDiv = img.closest('.cmp-image')
        const componentId = componentDiv ? escapeHtml(componentDiv.id, true) : null

        if (componentId) {
            const currentState = window.adobeDataLayer.getState(`component.${componentId}`);
            if (currentState) {
                const updatedState = {
                    ...currentState,
                    'dc:title': imageName
                };

                window.adobeDataLayer.push({
                    component: {
                        [componentId]: updatedState
                    }
                });
            }
        }
    });
}

export function fileDownloadTracking(file) {
    adobeDataLayer.push({
        event: FILE_DOWNLOAD,
        file: file
    })
}

export function syncAdobeTargetXfOffer() {
    var adobeTargetXfOffers = document.querySelectorAll('.at-element-marker')

    adobeTargetXfOffers.forEach(function (xfOffer) {
        var components = xfOffer.querySelectorAll('[data-cmp-data-layer]')
        components.forEach(function (component) {
            var componentObject = getComponentObject(component)
            var componentID = Object.keys(componentObject)[0]
            if (!window.adobeDataLayer.getState('component.' + componentID)) {
                addComponentToDataLayer(componentObject)
                if (component.hasAttribute('data-cmp-clickable')) {
                    attachClickEventListener(component)
                }
            }
        })
    })

    function addComponentToDataLayer(componentObject) {
        window.adobeDataLayer.push({
            component: componentObject
        })
    }

    function getComponentObject(element) {
        var component = getComponentData(element)
        var componentID = Object.keys(component)[0]
        if (
            component &&
            component[componentID] &&
            !component[componentID].parentId
        ) {
            var parentElement = element.parentNode.closest(
                '[data-cmp-data-layer], body'
            )
            if (parentElement)
                component[componentID].parentId = parentElement.id
        }
        return component
    }

    function getComponentData(element) {
        var dataLayerJson = element.dataset.cmpDataLayer
        if (dataLayerJson) return JSON.parse(dataLayerJson)
        else return undefined
    }

    function attachClickEventListener(element) {
        element.addEventListener('click', addClickToDataLayer)
    }

    function addClickToDataLayer(event) {
        var element = event.currentTarget
        var componentId = getClickId(element)
        window.adobeDataLayer.push({
            event: 'cmp:click',
            eventInfo: {
                path: 'component.' + componentId
            }
        })
    }
}

 //Carousel Tracking
 export function carouselClicksTracking(id, name, position) {
    // Initialize adobeDataLayer if it doesn't already exist
    window.adobeDataLayer = window.adobeDataLayer || []

    // Push the carousel click event to adobeDataLayer with additional details
    window.adobeDataLayer.push({
        event: "carousel:click",
        carousel: {
            id: id,
            name: name,
            position: position + 1
        }
    })

    console.log('Carousel click event pushed to adobeDataLayer:', window.adobeDataLayer)
    
}
