
import { getComponentPath } from "../dow-platform/components/core/ACDLHandler";
import {
    AF_COMPLETE,
    AF_CONTINUE,
    AF_ERROR,
    AF_INTERACTION,
    adaptiveForm,
    addAfInteractionListener,
    addAfNavigationListener,
    addAfSubmitListener,
    addAfValidationErrorListener
} from "../utils/aem-forms";

const FORM_START = 'form:start'
const FORM_CONTINUE = 'form:continue'
const FORM_COMPLETE = 'form:complete'
const FORM_ERROR = 'form:error'
const FORM_DEFAULTS = {
    started: false,
    step: 1,
    complete: false,
    error: '',
}

export default {
    namespaced: true,
    state: {
        forms: {},
        adaptiveFormPaths: []
    },
    mutations: {
        add(state, { form, name }) {
            let type
            if (typeof name == 'string') {
                type = name.split('/', 2)
                name = type.pop()
                type = type.pop()
            }

            state[form] = {
                ...FORM_DEFAULTS,
                name: name || form,
                type
            }
        },
        reset(state, form) {
            state[form] = {
                ...(state[form] || {}),
                ...FORM_DEFAULTS
            }
        },
        start(state, form) {
            state[form].started = true
        },
        incrementStep(state, form) {
            state[form].step++
        },
        addAfPath(state, path) {
            state.adaptiveFormPaths.push(path)
        }
    },
    actions: {
        trackInteraction({ state, commit, rootState }, form) {
            if (!state[form]) commit('add', { form,
                name: rootState.components.dataLayer[form]?.['dc:description'] })
            if (!state[form].started) {
                commit('start', form)
                window.adobeDataLayer.push({
                    event: FORM_START,
                    eventInfo: { path: getComponentPath(form) },
                    sampleForm: {
                        // convert proxy to object if it exists
                        product: store.state.sampleCart?.sampleCartItems ? setSampleTrackingProduct(store.state.sampleCart.sampleCartItems) : null,
                        shipTo: store.state.user?.dccUserInfo?.data?.selectedShipToId || null,
                        soldTo: store.state.user?.dccUserInfo?.data?.selectedSoldToId || null
                    },
                    form: {
                        step: state[form].step,
                        name: state[form].name,
                        type: state[form].type
                    }
                })
            }
        },
        trackContinue({ state, commit, dispatch }, form) {
            dispatch('trackInteraction', form)
            if (!state[form].complete) {
                commit('incrementStep', form)
                window.adobeDataLayer.push({
                    event: FORM_CONTINUE,
                    eventInfo: { path: getComponentPath(form) },
                    sampleForm: {
                        product: store.state.sampleCart?.sampleCartItems ? setSampleTrackingProduct(store.state.sampleCart.sampleCartItems) : null,
                        shipTo: store.state.user?.dccUserInfo?.data?.selectedShipToId || null,
                        soldTo: store.state.user?.dccUserInfo?.data?.selectedSoldToId || null
                    },
                    form: {
                        step: state[form].step,
                        name: state[form].name,
                        type: state[form].type
                    }
                })
            }
        },
        trackComplete({ state, commit, dispatch }, form) {
            dispatch('trackInteraction', form)
            if (!state[form].complete) {
                commit('incrementStep', form)
                window.adobeDataLayer.push({
                    event: FORM_COMPLETE,
                    eventInfo: { path: getComponentPath(form) },
                    sampleForm: {
                        product: store.state.sampleCart?.sampleCartItems ? setSampleTrackingProduct(store.state.sampleCart.sampleCartItems) : null,
                        shipTo: store.state.user?.dccUserInfo?.data?.selectedShipToId || null,
                        soldTo: store.state.user?.dccUserInfo?.data?.selectedSoldToId || null
                    },
                    form: {
                        step: state[form].step,
                        name: state[form].name,
                        type: state[form].type
                    }
                })
            }
        },
        trackError({ state, dispatch }, { form, error }) {
            dispatch('trackInteraction', form)
            window.adobeDataLayer.push({
                event: FORM_ERROR,
                eventInfo: { path: getComponentPath(form) },
                sampleForm: {
                    product: store.state.sampleCart?.sampleCartItems ? setSampleTrackingProduct(store.state.sampleCart.sampleCartItems) : null,
                    shipTo: store.state.user?.dccUserInfo?.data?.selectedShipToId || null,
                    soldTo: store.state.user?.dccUserInfo?.data?.selectedSoldToId || null
                },
                form: {
                    step: state[form].step,
                    error,
                    name: state[form].name,
                    type: state[form].type
                }
            })
        },
        adaptiveForms({ state, commit, dispatch }, forms) {
            const origin = `${window.location.protocol}//${window.location.host}`

            forms.forEach(formEl => {
                const { path, name, id } = adaptiveForm(formEl.dataset.formPagePath)
                
                if (name) {
                    createAfComponent(id, name)

                    if (formEl.tagName == 'IFRAME') {
                        window.addEventListener('message', event => {
                            if (event.origin == origin && event.source == formEl.contentWindow)
                                handleAfWindowMessage({ dispatch }, event.data)
                        })
                    } else {
                        const guideParents = []
                        commit('addAfPath', path)
                        addAfInteractionListener(formEl, event => dispatch('trackInteraction', id))
                        addAfValidationErrorListener(formEl, error => dispatch('trackError', { error, form: id }))
                        addAfNavigationListener(formEl, records => {
                            records.forEach(record => {
                                if (!guideParents.includes(record.target.dataset.guideParentId))
                                    guideParents.push(record.target.dataset.guideParentId)
                                else dispatch('trackContinue', id)
                            });
                        })
                    }
                }
            })

            if (state.adaptiveFormPaths.length) addAfSubmitListener(({ target: response }) => {
                const { path, id } = adaptiveForm(response.responseURL)
                
                if (state.adaptiveFormPaths.includes(path)) {
                    if (response.status >= 200 && response.status < 400) {
                        dispatch('trackComplete', id)
                    } else {
                        dispatch('trackError', { error: response.statusText, form: id })
                    }
                }
            })    
        }
    }
}

function createAfComponent(id, name) {
    window.adobeDataLayer.push({
        component: {
            [id]: {
                '@type': 'analytics/pseudo/forms/af',
                'dc:description': name
            }
        }
    })
}

const guideParents = []
function handleAfWindowMessage({ dispatch }, { event, id: form, error, parent }) {
    switch (event) {
        case AF_INTERACTION: dispatch('trackInteraction', form)
            break;
        case AF_CONTINUE:
            if (!guideParents.includes(parent))
                guideParents.push(parent)
            else dispatch('trackContinue', form)
            break;
        case AF_COMPLETE: dispatch('trackComplete', form)
            break;
        case AF_ERROR: dispatch('trackError', { error, form })
            break;
    }
}

function setSampleTrackingProduct(items){
    // convert proxy to array
    var products = JSON.parse(JSON.stringify(items));

    products.forEach((product) => {
        if (product.webProductID){
            product.SKU = product.webProductID
        } else if (product.materialNumber) {
            product.SKU = product.materialNumber
        }
    });
    
    return products;
}
