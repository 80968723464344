import i18nHelper from '../../../commerce/myAccount/helper/i18n-helper.js';
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    currentSelection: {
      type: Number,
      default: 0
    },
    hasList: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isActive: false,
      sortByDropdown: i18nHelper.getI18nMessage('sort.by.dropdown.Title')
    };
  },
  computed: {
    selectedOption: function () {
      if (this.options) {
        return this.options[this.currentSelection];
      }
      return '';
    }
  },
  methods: {
    onSelectOption: function (option) {
      this.$emit('select-dropdown', option);
      this.isActive = false;
    },
    toggleOnKeydown: function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.isActive = !this.isActive;
        this.$nextTick(function () {
          const focusableSelector = this.$refs.dropdown.querySelector('.delivery-truck-dropdown__selected');
          focusableSelector.focus();
        });
      }
    },
    toggleOnClick: function () {
      if (this.hasList) {
        this.isActive = !this.isActive;
        $(document).click(function (e) {
          if (!$(e.target).is('#dropdownUl')) {
            $('#dropdownUl').hide();
          }
        });
      }
    },
    trapKeyboardFocus(event) {
      const focusableSelector = this.$refs.dropdown.querySelectorAll('[tabindex]:not([tabindex="-1"])');
      const firstFocusableElement = focusableSelector[0];
      let lastFocusableElement = focusableSelector[focusableSelector.length - 1];
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (event.target === lastFocusableElement) {
          firstFocusableElement.focus();
        } else {
          $('.delivery-truck-dropdown__option:focus').next().focus();
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (event.target === firstFocusableElement) {
          lastFocusableElement.focus();
        } else {
          $('.delivery-truck-dropdown__option:focus').prev().focus();
        }
      } else if (event.key === 'Tab') {
        if (event.target === lastFocusableElement) {
          this.toggleOnClick();
        }
      }
    }
  }
};