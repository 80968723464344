import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "cmp-delivery-truck-logged-in__body"
};
const _hoisted_2 = {
  class: "cmp-delivery-truck-logged-in__subtitle"
};
const _hoisted_3 = {
  key: 0,
  class: "cmp-delivery-truck-logged-in__subtitleText"
};
const _hoisted_4 = {
  key: 1,
  class: "cmp-delivery-truck-logged-in__subtitleText"
};
const _hoisted_5 = {
  key: 2,
  class: "cmp-delivery-truck-logged-in__cartItemsText"
};
const _hoisted_6 = {
  class: "cmp-delivery-truck-logged-in__text"
};
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  class: "cmp-delivery-truck-logged-in__frame"
};
const _hoisted_9 = {
  key: 0,
  class: "cmp-delivery-truck-logged-in__advanced-filters"
};
const _hoisted_10 = {
  class: "cmp-delivery-truck-logged-in__advanced-filters-plus"
};
const _hoisted_11 = {
  key: 0,
  class: "cmp-delivery-truck-logged-in__advanced-filters-input"
};
const _hoisted_12 = ["placeholder"];
const _hoisted_13 = {
  class: "cmp-delivery-truck-logged-in__table"
};
const _hoisted_14 = {
  key: 0,
  class: "cmp-delivery-truck-logged-in__warning"
};
const _hoisted_15 = {
  class: "cmp-delivery-truck-logged-in__results"
};
const _hoisted_16 = {
  class: "cmp-delivery-truck-logged-in__pagination"
};
const _hoisted_17 = {
  class: "cmp-delivery-truck-logged-in__buttons"
};
const _hoisted_18 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoadingDots = _resolveComponent("LoadingDots");
  const _component_MultiSearchBar = _resolveComponent("MultiSearchBar");
  const _component_PlusSignIcon = _resolveComponent("PlusSignIcon");
  const _component_MinusSignIcon = _resolveComponent("MinusSignIcon");
  const _component_AddressTable = _resolveComponent("AddressTable");
  const _component_Pagination = _resolveComponent("Pagination");
  const _component_DeliveryTruckModal = _resolveComponent("DeliveryTruckModal");
  return _openBlock(), _createBlock(_Teleport, {
    to: _ctx.cmpEl
  }, [$options.isAuthenticated && !_ctx.hideTruck ? (_openBlock(), _createBlock(_component_DeliveryTruckModal, {
    key: 0,
    ref: "deliveryTruckLoggedInModal",
    title: $options.getHeaderTitle(),
    onOnClose: $options.clearModalData
  }, {
    default: _withCtx(() => [_createVNode(_component_LoadingDots, {
      loading: $options.loading
    }, null, 8, ["loading"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, [!_ctx.isNewUser ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createElementVNode("strong", null, _toDisplayString($options.getLabel('truck.modal.subTitle')), 1)])) : (_openBlock(), _createElementBlock("span", _hoisted_4, [_createElementVNode("strong", null, _toDisplayString($options.getLabel('truck.modal.firstMessage')), 1)])), $options.hasCartItems ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($options.getLabel('truck.modal.textItemsInCart')), 1)) : _createCommentVNode("", true)]), _createElementVNode("p", _hoisted_6, [_createElementVNode("span", {
      innerHTML: $options.contactSupportText
    }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [(_openBlock(), _createBlock(_component_MultiSearchBar, {
      key: _ctx.truckReloadKey,
      class: "search-bar",
      searchOptions: $options.searchOptions,
      onSelectSearch: $options.onSearch,
      showSearchButton: true,
      customSelectList: true,
      selectedAddressType: "DELIVERY",
      onIsClearSubmit: $options.setClearSubmit,
      clearAddressFilter: true
    }, null, 8, ["searchOptions", "onSelectSearch", "onIsClearSubmit"])), $options.isInternalUser ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("a", {
      class: "cmp-delivery-truck-logged-in__advanced-filters-button",
      href: "#",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.showAdvancedFilters = !_ctx.showAdvancedFilters)
    }, [_createElementVNode("span", null, _toDisplayString($options.getLabel('advanced.filters.Title')), 1), _createElementVNode("span", _hoisted_10, [!_ctx.showAdvancedFilters ? (_openBlock(), _createBlock(_component_PlusSignIcon, {
      key: 0
    })) : _createCommentVNode("", true), _ctx.showAdvancedFilters ? (_openBlock(), _createBlock(_component_MinusSignIcon, {
      key: 1
    })) : _createCommentVNode("", true)])]), _ctx.showAdvancedFilters ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: $options.getLabel('city.Title'),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.city = $event),
      onKeyup: _cache[2] || (_cache[2] = _withKeys((...args) => $options.searchIfPossible && $options.searchIfPossible(...args), ["enter"]))
    }, null, 40, _hoisted_12), [[_vModelText, _ctx.city]])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_13, [_ctx.enableTable ? (_openBlock(), _createBlock(_component_AddressTable, {
      addressType: _ctx.addressType,
      addresses: _ctx.displayedAddresses,
      selectedParentId: _ctx.selectedDeliveryAddressId,
      "onUpdate:selectedParent": _cache[3] || (_cache[3] = $event => _ctx.selectedDeliveryAddressId = $event),
      selectedChildId: _ctx.selectedCompanyAddressId,
      "onUpdate:selectedChild": $options.onSelectCompanyAddress,
      key: _ctx.truckReloadKey,
      isClearSubmit: _ctx.isClearSubmit
    }, null, 8, ["addressType", "addresses", "selectedParentId", "selectedChildId", "onUpdate:selectedChild", "isClearSubmit"])) : _createCommentVNode("", true)])]), $options.addresses.length >= _ctx.maxResults ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("p", null, [_createElementVNode("b", null, _toDisplayString($options.getLabel('orderManagement.deliveryList.address.search').replace('100', _ctx.maxResults.toString())), 1)]), _createElementVNode("p", null, _toDisplayString($options.getLabel('text.pagination.warning.refine.results')), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [(_openBlock(), _createBlock(_component_Pagination, {
      items: $options.addresses,
      onChangeItems: _cache[4] || (_cache[4] = $event => _ctx.displayedAddresses = $event),
      key: _ctx.truckReloadKey
    }, null, 8, ["items"]))]), _createElementVNode("div", _hoisted_17, [$options.defaultAddressSelected ? (_openBlock(), _createElementBlock("button", {
      key: 0,
      onClick: _cache[5] || (_cache[5] = (...args) => $options.closeTruckModal && $options.closeTruckModal(...args)),
      class: "cmp-button cmp-button--ghost-red cmp-button--lg delivery--cancel"
    }, _toDisplayString($options.getLabel('button.cancel.UPPER')), 1)) : _createCommentVNode("", true), _createElementVNode("button", {
      onClick: _cache[6] || (_cache[6] = (...args) => $options.onSubmit && $options.onSubmit(...args)),
      disabled: !$options.canSubmit,
      class: "cmp-button cmp-button--filled-red cmp-button--lg delivery--apply"
    }, _toDisplayString($options.getLabel('button.apply.UPPER')), 9, _hoisted_18)])])])]),
    _: 1
  }, 8, ["title", "onOnClose"])) : _createCommentVNode("", true)], 8, ["to"]);
}