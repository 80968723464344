import "core-js/modules/es.array.push.js";
import CoreComponent from './dow-platform/components/core/CoreComponent.vue';
import Container from './dow-platform/components/core/container/container.vue';
import Image from './dow-platform/components/core/image/image.vue';
import Text from './dow-platform/components/core/text/text.vue';
import Title from './dow-platform/components/core/title/title.vue';
import Button from './dow-platform/components/core/button/button.vue';
import Accordion from './dow-platform/components/core/accordion/accordion.vue';
import Hero from './dow-platform/components/core/hero/hero.vue';
import DiamondTv from './dow-platform/components/core/embed/embeddable/diamondtv/diamondtv.vue';
import Video from './dow-platform/components/core/embed/embeddable/video/video.vue';
import Youtube from './dow-platform/components/core/embed/embeddable/youtube/youtube.vue';
import DiamondTvProcessor from './dow-platform/components/core/embed/processors/diamondtv.vue';
import YouTubeProcessor from './dow-platform/components/core/embed/processors/youtube.vue';
import CtaBanner from './dow-platform/components/ui/ctaBanner/ctaBanner.vue';
import CardContainer from './dow-platform/components/ui/cardcontainer/cardcontainer.vue';
import Tabs from './dow-platform/components/core/tabs/Tabs.vue';
import Header from './dow-platform/components/ui/header/header.vue';
import LanguageSelector from './dow-platform/components/ui/header/language-selector/language-selector.vue';
import awardsCertificationBanner from './dow-platform/components/ui/heros/awardsCertificationBanner/awardsCertificationBanner.vue';
import MegaMenuNavigation from './dow-platform/components/ui/mega-menu-navigation/mega-menu-navigation.vue';
import heroSubPage from './dow-platform/components/ui/navigation/heroSubPage/heroSubPage.vue';
import Footer from './dow-platform/components/core/footer/footer.vue';
import TextMedia from './dow-platform/components/ui/text-media/text-media.vue';
import EventTextMedia from './dow-platform/components/ui/event-text-media/event-text-media.vue';
import Anchorlinks from './dow-platform/components/ui/anchorlinks/anchorlinks.vue';
import VideoPlayer from './dow-platform/components/ui/videoplayer/videoplayer.vue';
import FullWidthFeature from './dow-platform/components/ui/fullWidthFeature/fullWidthFeature.vue';
import CaseStudyBanner from './dow-platform/components/ui/caseStudyBanner/caseStudyBanner.vue';
import SustainabilityBanner from './dow-platform/components/ui/sustainability-banner/sustainability-banner.vue';
import HomepageAnnouncement from './dow-platform/components/ui/homepageAnnouncement/homepageAnnouncement.vue';
import Table from './dow-platform/components/ui/table/table.vue';
import HeroSubNavigation from './dow-platform/components/ui/heroSubNavigation/heroSubNavigation.vue';
import AdditionalResources from './dow-platform/components/ui/additional-resources/additional-resources.vue';
import ConsentBanner from './dow-platform/components/ui/consentBanner/consentBanner.vue';
import MegaMenu from './dow-platform/components/ui/megamenu/megamenu.vue';
import Interactive from './dow-platform/components/ui/interactive/interactive.vue';
import ContactCards from './dow-platform/components/ui/contactCards/contactCards.vue';
import SiteLogin from './dow-platform/components/ui/site-login/SiteLogin.vue';
import News from './dow-platform/components/ui/news/news.vue';
import TabNavigation from './dow-platform/components/utility/tabnavigation/tabnavigation.vue';
import ListCards from './dow-platform/components/utility/listCards/ListCards.vue';
import ResourceCarousel from './dow-platform/components/ui/resourceCarousel/resourceCarousel.vue';
import ContentCarousel from './dow-platform/components/ui/contentCarousel/contentCarousel.vue';
import ContentPanel from './dow-platform/components/ui/contentPanel/contentPanel.vue';
import BenefitsFeatures from './dow-platform/components/ui/benefits-features/benefits-features.vue';
import UtilitySearch from './dow-platform/components/utility/utility-search/utility-search.vue';
import UtilityHero from './dow-platform/components/utility/utility-hero/utility-hero.vue';
import SplitNavigationBar from './dow-platform/components/utility/splitNavigationBar/splitNavigationBar.vue';
import UtilityContactUs from './dow-platform/components/utility/utility-contact-us/utility-contact-us.vue';
import QuickLinkList from './dow-platform/components/ui/quickLinkList/quickLinkList.vue';
import TechnicalCardContent from './dow-platform/components/ui/technical-content-card/technical-content-card.vue';
import HomePageHero from './dow-platform/components/utility/homepageHero/homepageHero.vue';
import UtilityLinksNavigation from './dow-platform/components/utility/utilityLinksNavigation/utilityLinksNavigation.vue';
import UtilityProductBrandIndex from './dow-platform/components/utility/utility-product-brand-index/utility-product-brand-index.vue';
import PdfDownloadDropdown from './dow-platform/components/ui/pdf-download-dropdown/pdf-download-dropdown.vue';
import MicrositeHero from './dow-platform/components/ui/heros/micrositeHero/micrositeHero.vue';
import MicrositeQuotes from './dow-platform/components/ui/microsite-quotes/microsite-quotes.vue';
import UtilityHomepageSearch from './dow-platform/components/utility/homepage-search/homepage-search.vue';
import TriggeredAd from './dow-platform/components/ui/triggered-ad/triggered-ad.vue';
import InPageSubNavigation from './dow-platform/components/ui/navigation/inPageSubPage/inPageSubPage.vue';
import UtilityPhoneContact from './dow-platform/components/utility/utility-phone-contact/utility-phone-contact.vue';
import MainHeader from './dow-platform/components/utility/mainHeader/mainHeader.vue';
import DeliveryTruckLoggedIn from './dow-platform/components/ui/delivery-truck/delivery-truck-logged-in/delivery-truck-logged-in.vue';
import DeliveryTruckLoggedOut from './dow-platform/components/ui/delivery-truck/delivery-truck-logged-out/delivery-truck-logged-out.vue';
import PackagingCode from './dow-platform/components/ui/packagingCode/packagingCode.vue';
import Breadcrumb from './dow-platform/components/core/breadcrumb/breadcrumb.vue';
import Spacer from './dow-platform/components/ui/spacer/spacer.vue';
import Truck from './dow-platform/components/commerce/truck/Truck.vue';
import SearchBox from './dow-platform/components/forms/search-box/SearchBox.vue';
import ClpLabels from './dow-platform/components/commerce/clp-labels/clp-labels.vue';
import ListCard from './dow-platform/components/forms/listCard/ListCard.vue';
import ApplicationsOverview from './dow-platform/components/ui/applications-overview/applications-overview.vue';
var backToTopButton = require('./dow-platform/components/commerce/blocks/output-elements/back-to-top-button/index.js');
import { getCookiebyName } from '@/utils/cookies.js';
import { getParameter } from '../src/utils/url';
import { defineAsyncComponent, ref, provide } from 'vue';
const fallbacks = {
  loadingComponent: CoreComponent,
  errorComponent: CoreComponent
};
const CoveoComponent = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'results-list' */'./dow-platform/components/ui/coveoComponent/CoveoComponent.vue')
});
const CuratedProductSearch = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'curated-product-search' */'./dow-platform/components/ui/curatedProductSearch/curatedProductSearch.vue')
});
const ProductHighlight = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'product-highlight' */'./dow-platform/components/ui/product-highlight/ProductHighlight.vue')
});
const DocumentViewer = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'document-viewer' */'./dow-platform/components/commerce/document-viewer/document-viewer.vue')
});
const Area = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/area/area.vue')
});
const Bond = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/bond/bond.vue')
});
const Coat = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/coat/coat.vue')
});
const Cost = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/cost/cost.vue')
});
const Misc = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/misc/misc.vue')
});
const Roll = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/roll/roll.vue')
});
const Usage = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'area' */'./dow-platform/components/commerce/calculators/psaCalculator/usage/usage.vue')
});
const Pdp = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'pdp' */'./dow-platform/components/commerce/pdp/pdp.vue')
});
const SampleCart = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'sample-cart' */'./dow-platform/components/commerce/sample-cart/SampleCart.vue')
});
const MyAccountMenu = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'my-account' */'./dow-platform/components/commerce/myAccount/navigation/AccountMenu.vue')
});
const SampleNotAvailable = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'sample-not-available' */'./dow-platform/components/commerce/sample-not-available/sample-not-available.vue')
});
const MyResources = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'my-resources' */'./dow-platform/components/commerce/myResources/myResources.vue')
});
const BenefitsCalculator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'benefits-calculator' */'./dow-platform/components/ui/benefits-calculator/BenefitsCalculator.vue')
});
const Search = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'search' */'./dow-platform/components/commerce/search/search.vue')
});
const NewDistributorFinder = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'new-distributor-finder' */'./dow-platform/components/commerce/NewDistributorFinder/NewDistributorFinder.vue')
});
const SDSFinder = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'sds-finder' */'./dow-platform/components/commerce/sdsFinder/sdsFinder.vue')
});
const InPageGlobalSearch = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'in-page-global-search' */'./dow-platform/components/commerce/inPageGlobalSearch/inPageGlobalSearch.vue')
});
const ProductCompare = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'product-compare' */'./dow-platform/components/commerce/product-compare/product-compare.vue')
});
const FormulationCompass = defineAsyncComponent({
  ...fallbacks,
  loader: () => import( /* webpackChunkName: 'formulation-compass' */'./dow-platform/components/core/embed/embeddable/formulation-compass/FormulationCompass.vue')
});
const SealantUsageEstimator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'sealant-usage-estimator' */'./dow-platform/components/commerce/calculators/construction-calculators/sealant-usage-estimator/SealantUsageEstimator.vue')
});
const CircularPanelCalculation = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'circular-panel-calculation' */'./dow-platform/components/commerce/calculators/construction-calculators/circular-panel-calculation/CircularPanelCalculation.vue')
});
const RectangularPanelCalculation = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'rectangular-panel-calculation' */'./dow-platform/components/commerce/calculators/construction-calculators/rectangular-panel-calculation/RectangularPanelCalculation.vue')
});
const TriangularPanelCalculation = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'triangular-panel-calculation' */'./dow-platform/components/commerce/calculators/construction-calculators/triangular-panel-calculation/TriangularPanelCalculation.vue')
});
const DeadloadCalculator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'deadload-calculator' */'./dow-platform/components/commerce/calculators/construction-calculators/deadload-calculator/DeadloadCalculator.vue')
});
const DowsilCalculator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'dowsil-calculator' */'./dow-platform/components/commerce/calculators/construction-calculators/dowsil-calculator/DowsilCalculator.vue')
});
const PrimerUsageEstimator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'primer-usage-estimator' */'./dow-platform/components/commerce/calculators/construction-calculators/primer-usage-estimator/PrimerUsageEstimator.vue')
});
const ThermalMovementCalculator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'thermal-movement-calculator' */'./dow-platform/components/commerce/calculators/construction-calculators/thermal-movement-calculator/ThermalMovementCalculator.vue')
});
const PolymerCalculator = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'polymer-calculator' */'./dow-platform/components/commerce/calculators/polymer-calculator/PolymerCalculator.vue')
});
const Drawer = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'drawer' */'./dow-platform/components/commerce/drawer/Drawer.vue')
});
const MarketOffer = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: market-offer' */'./dow-platform/components/commerce/market-offer/MarketOffer.vue')
});
const AccountRecovery = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'account-recovery' */'./dow-platform/components/commerce/account-recovery/AccountRecovery.vue')
});
const SSOLogin = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'sso-login' */'./dow-platform/components/ui/sso-login/sso-login.vue')
});
const SimpleRegistration = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'simple-registration' */'./dow-platform/components/commerce/simple-registration/SimpleRegistration.vue')
});
const IndustrialRelease = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'industrial-release' */'./dow-platform/components/commerce/industrial-release/IndustrialRelease.vue')
});
const Antifoam = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'antifoam' */'./dow-platform/components/commerce/antifoam/antifoamRouter.vue')
});
const QualityBond = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'quality-bond' */'./dow-platform/components/commerce/quality-bond/QualityBond.vue')
});
const DistributorDetails = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'distributorDetails' */'./dow-platform/components/commerce/distributorDetails/distributorDetails.vue')
});
const BeautyCare = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: beauty-care' */'./dow-platform/components/commerce/beautyCare/beautyCareRouter.vue')
});
const DistributorInquiry = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'distributor-inquiry' */'./dow-platform/components/commerce/distributor-inquiry/DistributorInquiry.vue')
});
const ContactUs = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'contact-us' */'./dow-platform/components/commerce/contact-us/contact-us.vue')
});
const QuickOrder = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'quickOrder' */'./dow-platform/components/commerce/quickOrder/quickOrder.vue')
});
const EventCard = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'event-card' */'./dow-platform/components/ui/event-card/event-card.vue')
});
const Event = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'event' */'./dow-platform/components/ui/event/event.vue')
});
const UtilityEventList = defineAsyncComponent({
  ...fallbacks,
  // Override for custom messaging
  loader: () => import( /* webpackChunkName: 'utility-event-list' */'./dow-platform/components/utility/utility-event-list/utility-event-list.vue')
});
export default {
  setup() {
    const deliveryTruckLoggedInOpen = ref(false);
    const deliveryTruckLoggedInOpenModal = () => {
      deliveryTruckLoggedInOpen.value = true;
    };
    const deliveryTruckLoggedInCloseModal = () => {
      deliveryTruckLoggedInOpen.value = false;
    };
    provide('deliveryTruckModal', {
      deliveryTruckLoggedInOpen,
      deliveryTruckLoggedInOpenModal,
      deliveryTruckLoggedInCloseModal
    });
    const isLanguageDropdownOpen = ref(false);
    const toggleLangugageDropdown = () => {
      if (isLanguageDropdownOpen.value === true) {
        isLanguageDropdownOpen.value = false;
      } else {
        isLanguageDropdownOpen.value = true;
      }
    };
    const closeLanguageDropdown = () => {
      isLanguageDropdownOpen.value = false;
    };
    provide('languageDropDownModal', {
      isLanguageDropdownOpen,
      toggleLangugageDropdown,
      closeLanguageDropdown
    });
  },
  components: {
    CoreComponent,
    'dow-platform/components/core/container': Container,
    'dow-platform/components/core/image': Image,
    'dow-platform/components/core/text': Text,
    'dow-platform/components/core/title': Title,
    'dow-platform/components/core/button': Button,
    'dow-platform/components/core/accordion': Accordion,
    'dow-platform/components/core/hero': Hero,
    'dow-platform/components/core/embed/embeddable/diamondtv': DiamondTv,
    'dow-platform/components/core/embed/embeddable/video': Video,
    'dow-platform/components/core/embed/embeddable/youtube': Youtube,
    'dow-platform/components/core/embed/processors/diamondtv': DiamondTvProcessor,
    'dow-platform/components/core/embed/processors/youtube': YouTubeProcessor,
    'dow-platform/components/ui/ctaBanner': CtaBanner,
    'dow-platform/components/ui/cardcontainer': CardContainer,
    'dow-platform/components/ui/product-highlight': ProductHighlight,
    'dow-platform/components/core/tabs': Tabs,
    'dow-platform/components/forms/search-box': SearchBox,
    'dow-platform/components/ui/heros/awardsCertificationBanner': awardsCertificationBanner,
    'dow-platform/components/ui/navigation/heroSubPage': heroSubPage,
    'dow-platform/components/core/footer': Footer,
    'dow-platform/components/ui/header': Header,
    'dow-platform/components/nested/header/language': LanguageSelector,
    'dow-platform/components/ui/text-media': TextMedia,
    'dow-platform/components/ui/event-text-media': EventTextMedia,
    'dow-platform/components/ui/anchorlinks': Anchorlinks,
    'dow-platform/components/ui/curatedProductSearch': CuratedProductSearch,
    'dow-platform/components/ui/coveoComponent': CoveoComponent,
    'dow-platform/components/ui/videoplayer': VideoPlayer,
    'dow-platform/components/ui/mega-menu-navigation': MegaMenuNavigation,
    'dow-platform/components/ui/fullwidthfeature': FullWidthFeature,
    'dow-platform/components/ui/caseStudyBanner': CaseStudyBanner,
    'dow-platform/components/ui/sustainability-banner': SustainabilityBanner,
    'dow-platform/components/ui/homepageAnnouncement': HomepageAnnouncement,
    'dow-platform/components/ui/table': Table,
    'dow-platform/components/ui/heroSubNavigation': HeroSubNavigation,
    'dow-platform/components/ui/additional-resources': AdditionalResources,
    'dow-platform/components/ui/consentBanner': ConsentBanner,
    'dow-platform/components/ui/interactive': Interactive,
    'dow-platform/components/ui/contactCards': ContactCards,
    'dow-platform/components/ui/site-login': SiteLogin,
    'dow-platform/components/commerce/account-recovery': AccountRecovery,
    'dow-platform/components/commerce/antifoam': Antifoam,
    'dow-platform/components/commerce/beautyCare': BeautyCare,
    'dow-platform/components/ui/news': News,
    'dow-platform/components/ui/mega-menu': MegaMenu,
    'dow-platform/components/commerce/sample-not-available': SampleNotAvailable,
    'dow-platform/components/utility/tabnavigation': TabNavigation,
    'dow-platform/components/utility/listcards': ListCards,
    'dow-platform/components/ui/resourceCarousel': ResourceCarousel,
    'dow-platform/components/ui/contentCarousel': ContentCarousel,
    'dow-platform/components/ui/contentPanel': ContentPanel,
    'dow-platform/components/ui/benefits-features': BenefitsFeatures,
    'dow-platform/components/utility/utility-search': UtilitySearch,
    'dow-platform/components/commerce/pdp': Pdp,
    'dow-platform/components/commerce/sample-cart': SampleCart,
    'dow-platform/components/utility/utility-hero': UtilityHero,
    'dow-platform/components/utility/splitNavigationBar': SplitNavigationBar,
    'dow-platform/components/utility/utility-contact-us': UtilityContactUs,
    'dow-platform/components/commerce/myAccountMenu': MyAccountMenu,
    'dow-platform/components/ui/quickLinkList': QuickLinkList,
    'dow-platform/components/ui/technical-content-card': TechnicalCardContent,
    'dow-platform/components/utility/homepageHero': HomePageHero,
    'dow-platform/components/utility/utilityLinksNavigation': UtilityLinksNavigation,
    'dow-platform/components/utility/utility-product-brand-index': UtilityProductBrandIndex,
    'dow-platform/components/ui/pdf-download-dropdown': PdfDownloadDropdown,
    'dow-platform/components/ui/event-card': EventCard,
    'dow-platform/components/ui/event': Event,
    'dow-platform/components/utility/utility-event-list': UtilityEventList,
    'dow-platform/components/ui/heros/micrositeHero': MicrositeHero,
    'dow-platform/components/ui/microsite-quotes': MicrositeQuotes,
    'dow-platform/components/ui/sso-login': SSOLogin,
    'dow-platform/components/ui/benefits-calculator': BenefitsCalculator,
    'dow-platform/components/utility/homepage-search': UtilityHomepageSearch,
    'dow-platform/components/commerce/simple-registration': SimpleRegistration,
    'dow-platform/components/commerce/market-offer': MarketOffer,
    'dow-platform/components/ui/triggered-ad': TriggeredAd,
    'dow-platform/components/ui/navigation/inPageSubPage': InPageSubNavigation,
    'dow-platform/components/commerce/myResources': MyResources,
    'dow-platform/components/utility/utility-phone-contact': UtilityPhoneContact,
    'dow-platform/components/commerce/search': Search,
    'dow-platform/components/commerce/calculators/construction-calculators/sealant-usage-estimator': SealantUsageEstimator,
    'dow-platform/components/commerce/calculators/construction-calculators/circular-panel-calculation': CircularPanelCalculation,
    'dow-platform/components/commerce/calculators/construction-calculators/rectangular-panel-calculation': RectangularPanelCalculation,
    'dow-platform/components/commerce/calculators/construction-calculators/triangular-panel-calculation': TriangularPanelCalculation,
    'dow-platform/components/commerce/calculators/construction-calculators/deadload-calculator': DeadloadCalculator,
    'dow-platform/components/commerce/calculators/construction-calculators/dowsil-calculator': DowsilCalculator,
    'dow-platform/components/commerce/calculators/construction-calculators/primer-usage-estimator': PrimerUsageEstimator,
    'dow-platform/components/commerce/calculators/construction-calculators/thermal-movement-calculator': ThermalMovementCalculator,
    'dow-platform/components/commerce/distributor-inquiry': DistributorInquiry,
    'dow-platform/components/utility/mainHeader': MainHeader,
    'dow-platform/components/commerce/sdsFinder': SDSFinder,
    'dow-platform/components/commerce/distributorDetails': DistributorDetails,
    'dow-platform/components/commerce/newDistributorFinder': NewDistributorFinder,
    'dow-platform/components/commerce/documentViewer': DocumentViewer,
    'dow-platform/components/commerce/product-compare': ProductCompare,
    'dow-platform/components/core/embed/embeddable/formulationcompass': FormulationCompass,
    'dow-platform/components/commerce/calculators/polymer-calculator': PolymerCalculator,
    'dow-platform/components/commerce/industrial-release': IndustrialRelease,
    'dow-platform/components/commerce/drawer': Drawer,
    'dow-platform/components/commerce/quality-bond': QualityBond,
    'dow-platform/components/commerce/calculators/psaCalculator/area': Area,
    'dow-platform/components/commerce/calculators/psaCalculator/bond': Bond,
    'dow-platform/components/commerce/calculators/psaCalculator/coat': Coat,
    'dow-platform/components/commerce/calculators/psaCalculator/cost': Cost,
    'dow-platform/components/commerce/calculators/psaCalculator/misc': Misc,
    'dow-platform/components/commerce/calculators/psaCalculator/roll': Roll,
    'dow-platform/components/commerce/calculators/psaCalculator/usage': Usage,
    'dow-platform/components/commerce/inPageGlobalSearch': InPageGlobalSearch,
    'dow-platform/components/ui/delivery-truck/delivery-truck-logged-in': DeliveryTruckLoggedIn,
    'dow-platform/components/ui/delivery-truck/delivery-truck-logged-out': DeliveryTruckLoggedOut,
    'dow-platform/components/ui/packagingCode/packagingCode': PackagingCode,
    'dow-platform/components/commerce/contact-us': ContactUs,
    'dow-platform/components/core/breadcrumb': Breadcrumb,
    'dow-platform/components/ui/spacer': Spacer,
    'dow-platform/components/commerce/truck': Truck,
    'dow-platform/components/commerce/quickOrder': QuickOrder,
    'dow-platform/components/commerce/clp-labels': ClpLabels,
    'dow-platform/components/forms/listcard': ListCard,
    'dow-platform/components/ui/applications-overview': ApplicationsOverview
  },
  created() {
    let cid = getParameter('cid');
    if (cid) {
      this.$store.dispatch('browser/setBrowserCookie', {
        key: 'elqQueryString',
        value: 'cid=' + cid
      });
    }
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(dl => {
      dl.addEventListener('adobeDataLayer:change', event => this.$store.dispatch('dataLayerUpdate', event));
      dl.addEventListener('adobeDataLayer:event', event => this.$store.dispatch('dataLayerEvent', event));
    });
    this.$store.dispatch('form/adaptiveForms', document.querySelectorAll('[data-form-page-path]')); //data-form-page-path="/content/forms/af/test-blank-form"

    const languageCode = getCookiebyName('languageCode');
    const documentLang = document.documentElement.lang.toLowerCase();
    if (languageCode !== documentLang) {
      this.$store.dispatch('browser/setLanguageCode', documentLang);
    }
    this.$store.dispatch('browser/setLocation', {});

    //window.refresh_jsession()
  },
  mounted() {
    window.addEventListener('storage', event => {
      if (event.key === 'logout' && event.newValue) {
        location.reload();
      }
    });
  }
};