import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "delivery-truck-modal",
  ref: "modal"
};
const _hoisted_2 = {
  key: 0,
  class: "delivery-truck-modal__content"
};
const _hoisted_3 = {
  class: "delivery-truck-modal__header"
};
const _hoisted_4 = {
  class: "delivery-truck-modal__title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TruckIcon = _resolveComponent("TruckIcon");
  const _component_CloseIcon = _resolveComponent("CloseIcon");
  return _openBlock(), _createBlock(_Transition, {
    name: "delivery-truck-modal-fade"
  }, {
    default: _withCtx(() => [$data.isModalOpen ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.isModalOpen ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h4", _hoisted_4, [!$data.hideIcon ? (_openBlock(), _createBlock(_component_TruckIcon, {
      key: 0
    })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.title), 1)]), !$data.hideIcon ? (_openBlock(), _createBlock(_component_CloseIcon, {
      key: 0,
      class: "closeDeliveryTruckModal",
      onClick: $options.closeModal
    }, null, 8, ["onClick"])) : _createCommentVNode("", true)]), _renderSlot(_ctx.$slots, "default")])) : _createCommentVNode("", true)], 512)) : _createCommentVNode("", true)]),
    _: 3
  });
}