import { useTransitionFallthroughEmits } from 'element-plus';
import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data() {
    return {
      selectedFilter: 'All',
      inputValue: '',
      baseUrl: '',
      inputField: null,
      magnifierButton: null,
      clearSearchButton: null
    };
  },
  watch: {
    searchUrl(newValue) {
      this.magnifierButton.href = newValue;
    }
  },
  computed: {
    searchUrl() {
      let url = `${this.baseUrl}#t=${this.selectedFilter}&sort=relevancy`;
      if (this.inputValue !== '') url += `&q=${this.inputValue}`;
      if (this.inputValue.toLowerCase() === 'jobs' || this.inputValue.toLowerCase() === 'careers') {
        url = "https://corporate.dow.com/en-us/careers.html";
      }
      if (this.inputValue.toLowerCase() === 'retire' || this.inputValue.toLowerCase() === 'retirement') {
        url = "https://corporate.dow.com/en-us/benefits/retiree-and-alumni.html";
      }
      return url;
    }
  },
  methods: {
    addFocusOnFilters(event) {
      this.selectedFilter = event.target.getAttribute('data-filter');
      this.cmpEl.querySelector('.cmp--utility-homepage-search-filter--single-active').classList.remove('cmp--utility-homepage-search-filter--single-active');
      event.target.classList.add('cmp--utility-homepage-search-filter--single-active');
    },
    clearSearchInput(event) {
      event.preventDefault();
      this.clearSearchButton.classList.add('cmp--utility-homepage-search-hidden');
      this.inputField.focus();
      this.inputField.value = '';
      this.inputValue = '';
    },
    displayClearInput(event) {
      event.preventDefault();
      this.inputValue = event.target.value;
      if (this.inputValue === '') {
        this.clearSearchButton.classList.add('cmp--utility-homepage-search-hidden');
        this.magnifierButton.classList.remove('cmp--utility-homepage-search-magnifier-button--typed');
      } else {
        this.clearSearchButton.classList.remove('cmp--utility-homepage-search-hidden');
        this.magnifierButton.classList.add('cmp--utility-homepage-search-magnifier-button--typed');
      }
    },
    searchInputValue(event) {
      if (event.key === 'Enter') {
        this.inputValue = event.target.value;
        event.target.value = '';
        window.location.href = encodeURI(this.searchUrl);
      }
    }
  },
  mounted() {
    this.inputField = this.cmpEl.querySelector('.cmp--utility-homepage-search-input');
    this.magnifierButton = this.cmpEl.querySelector('.cmp--utility-homepage-search-magnifier-button');
    this.clearSearchButton = this.cmpEl.querySelector('.cmp--utility-homepage-search-clear-button');
    this.inputValue = this.inputField.value;
    this.magnifierButton.href = this.searchUrl;
    this.baseUrl = window.location.origin + this.cmpEl.dataset.baseSearchUrl;
    this.clearSearchButton.addEventListener('click', this.clearSearchInput);
    this.inputField.addEventListener('keyup', this.displayClearInput);
    this.inputField.addEventListener('keypress', this.searchInputValue);
    this.magnifierButton.addEventListener('click', e => {
      e.preventDefault();
      this.inputField.value = '';
      window.location.href = encodeURI(this.searchUrl);
    });
    this.cmpEl.querySelectorAll('.cmp--utility-homepage-search-filter--single').forEach(item => {
      item.querySelector('a').addEventListener('click', this.addFocusOnFilters);
    });
  }
};