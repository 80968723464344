import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  methods: {
    closeBanner(event) {
      event.preventDefault();
      let banner = event.target.parentElement.parentElement.parentElement.parentElement.parentElement;
      banner.style.display = 'none';
    }
  },
  mounted() {
    const isHidden = this.cmpEl.getAttribute('data-homepage-announcement-ishidden');
    const endDate = this.cmpEl.getAttribute('data-homepage-announcement-enddate');
    if (isHidden === 'true') {
      this.cmpEl.setAttribute('hidden', true);
    }
    if (endDate) {
      const timestamp = parseInt(endDate.match(/time=(\d+)/)[1]);
      if (new Date() > new Date(timestamp)) {
        this.cmpEl.setAttribute('hidden', true);
      }
    }
    let buttons = this.cmpEl.querySelectorAll('.cmp-button');
    buttons.forEach(button => {
      button.addEventListener('click', this.closeBanner);
    });
  }
};