import CoreComponent from '../../../core/CoreComponent.vue';
import ArrowIcon from '../../../commerce/blocks/svg-elements/arrow-right-large/ArrowRightLarge.vue';
import SmallArrow from '../../../commerce/blocks/svg-elements/arrow/arrow.vue';
import { inject } from 'vue';
export default {
  extends: CoreComponent,
  props: ['languageList', 'machineLanguageList', 'mobileAndTabletCheck'],
  components: {
    ArrowIcon,
    SmallArrow
  },
  setup() {
    const languageDropDownModal = inject('languageDropDownModal');
    return {
      languageDropDownModal
    };
  },
  mounted() {
    this.setIsMobileOrTablet();
  },
  unmounted() {},
  methods: {
    getLabel(key) {
      return window.Granite.I18n.get(key);
    },
    async switchLanguage(languageCode, path) {
      await this.$store.dispatch('browser/setLanguageCode', languageCode);
      if (window.location.href.includes('/dccstorefront/')) {
        window.location.reload();
      } else {
        window.location.href = path;
      }
    },
    toggleLanguageModal() {
      this.languageDropDownModal.toggleLangugageDropdown();
    },
    setIsMobileOrTablet() {},
    handleKeyboardNavigation() {
      const links = document.querySelectorAll('.cmp--header_language-modal .cmp--header_language-modal_option');
      if (links.length) {
        links.forEach((link, index) => {
          link.addEventListener('keydown', function (event) {
            const key = event.key;
            const prev = links[index - 1];
            const next = links[index + 1];
            if (key === 'ArrowUp') {
              event.preventDefault();
              if (prev) {
                prev.focus();
              } else {
                links[links.length - 1].focus();
              }
            }
            if (key === 'ArrowDown') {
              event.preventDefault();
              if (next) {
                next.focus();
              } else {
                links[0].focus();
              }
            }
          });
        });
      }
    }
  },
  computed: {
    currentLang() {
      return this.cmpEmbeddableData.currentLanguage;
    },
    linkURL() {
      return this.cmpEmbeddableData.linkURL;
    },
    languageKeys() {
      return this.languageList != null ? Object.keys(this.languageList) : [];
    },
    machineLanguageKeys() {
      return this.machineLanguageList != null ? Object.keys(this.machineLanguageList) : [];
    }
  }
};